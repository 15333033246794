import React, {
  memo,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import './TaskModal.less';
import { message, Modal } from 'antd';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { useTypedSelector } from '@hooks';
import {
  closeTaskModal,
  disposeTaskModal,
  openTaskModal
} from '@redux/reducers/tasks';
import classNames from 'classnames';
import { taskStateSelector } from '@redux/selectors/tasks';
import { fetchTaskById } from '@redux/actions/tasks';
import { TaskModalState } from '@redux/types/tasks';
import { useLocation, useSearchParams } from 'react-router-dom';
import { nonNull } from '@helpers/non-null';
import { SerializedError } from '@reduxjs/toolkit';
import { useTaskAttachmentsUppy } from '@context/TaskAttachmentsUppyContext';
import { useFetch } from '@hooks/useFetch';
import { OnboardingProcessContext } from '@context/OnboardingProcessProvider';
import { useCurrentWorkspace } from '@hooks/workspace';

const TaskModalInner = React.lazy(
  () => import('@components/Tasks/TaskModal/TaskModalInner')
);

interface TaskModalProps {
  modalState: TaskModalState;
  setGoToStep: (v: number) => void;
}

const TaskModal = memo(function TaskModal({
  modalState,
  setGoToStep
}: TaskModalProps) {
  const {
    taskId,
    open,
    type,
    descriptionPlaceholder,
    taskPosition,
    autoAssignMe
  } = modalState;
  const isNewTask = type === 'new';
  const dispatch = useTypedDispatch();
  const loading = useTypedSelector(
    (state) => !taskStateSelector(state, { taskId }).fetch.initiallyLoaded
  );
  const notFound = useTypedSelector(
    (state) => taskStateSelector(state, { taskId }).notFound
  );
  const deleted = useTypedSelector(
    (state) => !!taskStateSelector(state, { taskId }).entity?.deletedAt
  );
  const permanentlyDeleted = useTypedSelector(
    (state) => taskStateSelector(state, { taskId }).permanentlyDeleted
  );

  const onClose = useCallback(() => {
    dispatch(closeTaskModal());
  }, [dispatch]);

  useFetch({
    key: `task-${taskId}`,
    disabled: isNewTask,
    selector: (state) => taskStateSelector(state, { taskId }).fetch,
    fetch: (fetchType) => {
      const promise = dispatch(
        fetchTaskById({
          id: taskId,
          fetchType
        })
      );
      promise.unwrap().catch((err: SerializedError) => {
        if (err.code === '404') return;
        onClose();
        message.error({
          content: 'Something went wrong',
          className: 'message-dark-modal'
        });
      });
      return promise;
    }
  });

  useEffect(() => {
    if (!notFound || !open) return;
    onClose();
    message.error({
      content: 'Task not found',
      className: 'message-dark-modal'
    });
  }, [notFound, open, onClose]);

  useEffect(() => {
    if (!permanentlyDeleted || !open) return;
    onClose();
    message.error({
      content: 'This task was permanently deleted',
      className: 'message-dark-modal'
    });
  }, [permanentlyDeleted, open, onClose]);

  useEffect(() => {
    if (open) return;
    const timeoutId = setTimeout(() => {
      dispatch(disposeTaskModal());
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [open, dispatch]);

  const location = useLocation();
  const prevPath = useRef(location.pathname);
  useEffect(() => {
    if (location.pathname !== prevPath.current) {
      prevPath.current = location.pathname;
      onClose();
    }
  }, [location.pathname, onClose]);

  const [showDropzone, setShowDropzone] = useState(false);
  const dragEnterCounter = useRef(0);

  return (
    <Suspense
      fallback={
        <Modal
          open={open}
          footer={null}
          onCancel={onClose}
          wrapClassName="task-modal"
        />
      }
    >
      <Modal
        open={open}
        footer={null}
        centered={true}
        width={912}
        onCancel={onClose}
        zIndex={999}
        wrapClassName={classNames({
          'task-modal': true,
          'task-modal--loading': loading
        })}
        wrapProps={{
          onDragOver: (e: React.DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            e.dataTransfer.dropEffect = 'none';
            if (!deleted && e.dataTransfer.types.includes('Files')) {
              const target = e.target as HTMLElement;
              if (!target.classList.contains('ant-modal-wrap')) {
                e.dataTransfer.dropEffect = 'copy';
              }
            }
          },
          onDragEnter: (e: React.DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            dragEnterCounter.current += 1;
            setShowDropzone(!deleted && e.dataTransfer.types.includes('Files'));
          },
          onDragLeave: (e: React.DragEvent) => {
            e.stopPropagation();
            dragEnterCounter.current -= 1;
            if (dragEnterCounter.current <= 0) {
              dragEnterCounter.current = 0;
              setShowDropzone(false);
            }
          },
          onDrop: (e: React.DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            dragEnterCounter.current = 0;
            setShowDropzone(false);
          }
        }}
      >
        {!loading && (
          <TaskModalInner
            isNewTask={isNewTask}
            autoAssignMe={autoAssignMe}
            taskId={taskId}
            descriptionPlaceholder={descriptionPlaceholder}
            taskPosition={taskPosition}
            onClose={onClose}
            showDropzone={showDropzone}
            setGoToStep={setGoToStep}
          />
        )}
      </Modal>
    </Suspense>
  );
});

function usePendingAttachmentsCleanup(taskId: string | undefined) {
  const uppy = useTaskAttachmentsUppy();
  const prevTaskId = useRef(taskId);
  if (taskId !== prevTaskId.current) {
    const pendingAttachments = uppy
      .getFiles()
      .filter((x) => !x.progress?.uploadStarted);
    pendingAttachments.forEach((x) => uppy.removeFile(x.id));
    prevTaskId.current = taskId;
  }
}

function useTaskIdQuerySync(
  taskId: string | undefined,
  isNewTask: boolean,
  open: boolean
) {
  const dispatch = useTypedDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const taskIdParam = searchParams.get('taskId') ?? undefined;
  const prevTaskIdParam = useRef<string>();
  const prevOpen = useRef(open);
  useEffect(() => {
    const animationFrameId = requestAnimationFrame(() => {
      if (taskIdParam !== prevTaskIdParam.current) {
        prevTaskIdParam.current = taskIdParam;
        if (taskIdParam && (!open || taskIdParam !== taskId)) {
          dispatch(openTaskModal({ taskId: taskIdParam }));
        } else if (!taskIdParam && open) {
          dispatch(closeTaskModal());
        }
      }
      if (open !== prevOpen.current) {
        prevOpen.current = open;
        if (open && (!taskIdParam || taskIdParam !== taskId)) {
          if (isNewTask) searchParams.delete('taskId');
          else searchParams.set('taskId', nonNull(taskId));
          setSearchParams(searchParams);
        }
        if (!open && taskIdParam) {
          searchParams.delete('taskId');
          setSearchParams(searchParams, { replace: true });
        }
      }
    });
    return () => cancelAnimationFrame(animationFrameId);
  }, [
    taskIdParam,
    taskId,
    isNewTask,
    open,
    searchParams,
    setSearchParams,
    dispatch
  ]);
}

export default memo(function TaskModalWrapper() {
  const modalState = useTypedSelector((state) => state.tasks.modal);
  const { setGoToStep } = useContext(OnboardingProcessContext);
  const [currentWorkspace] = useCurrentWorkspace(false);
  const open = !!modalState?.open;
  const isNewTask = modalState?.type === 'new';
  const taskId = modalState?.taskId;
  useTaskIdQuerySync(taskId, isNewTask, open);
  usePendingAttachmentsCleanup(taskId);
  if (!modalState || !currentWorkspace) return null;
  return (
    <TaskModal
      key={modalState.key}
      modalState={modalState}
      setGoToStep={setGoToStep}
    />
  );
});
