import React, { memo, useCallback, useEffect } from 'react';
import { Button } from 'antd';
import './TasksCard.less';
import { useTypedSelector } from '@hooks';
import { toggleCampaignFolderCreationModal } from '@redux/actions/modalAction';
import TaskListSkeleton from '@components/Tasks/TaskListSkeleton';
import { useCurrentWorkspace } from '@hooks/workspace';
import {
  loadMoreDashboardTasks,
  openNewTaskModal,
  setDashboardListNeedRefresh,
  setDashboardTaskListParams
} from '@redux/reducers/tasks';
import { nonNull } from '@helpers/non-null';
import {
  workspaceStateSelector,
  filterCampaignsSelector
} from '@redux/selectors/tasks';
import useTypedDispatch from '@hooks/useTypedDispatch';
import {
  fetchCampaignsForFilter,
  fetchDashboardTaskList,
  fetchDashboardTasksCount
} from '@redux/actions/tasks';
import { useFetch } from '@hooks/useFetch';
import { InfiniteScroll } from '@components/InfiniteScroll/InfiniteScroll';
import LottieComponent from '@components/Lottie';
import { useAuth } from '@hooks/useAuth';
import { ReactComponent as EmptySvg } from '@assets/icons/empty-campaigns.svg';
import { ReactComponent as StartTask } from '@assets/icons/start-task.svg';

import { TasksInDashboardListType } from '@redux/types/tasks';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import DashboardTasksFilter from '@pages/Dashboard/components/DashboardFilter/DashboardTasksFilter';
import TaskItem from '@pages/Dashboard/components/TasksCard/TaskItem';

export default memo(function DashboardTasksList() {
  const dispatch = useTypedDispatch();
  const { user } = useAuth(true);
  const [currentWorkspace] = useCurrentWorkspace(true);
  const workspaceId = currentWorkspace.id;

  const count = useTypedSelector(
    (state) => workspaceStateSelector(state, { workspaceId }).dashboard.count
  );
  const taskIds = useTypedSelector(
    (state) => workspaceStateSelector(state, { workspaceId }).dashboard.taskIds
  );
  const initiallyLoaded = useTypedSelector(
    (state) =>
      workspaceStateSelector(state, { workspaceId }).dashboard.fetch
        .initiallyLoaded
  );
  const hasMore = useTypedSelector(
    (state) => workspaceStateSelector(state, { workspaceId }).dashboard.hasMore
  );
  const listType = useTypedSelector(
    (state) => workspaceStateSelector(state, { workspaceId }).dashboard.listType
  );
  const isExistCampaign = useTypedSelector(
    (state) =>
      workspaceStateSelector(state, {
        workspaceId
      }).listsShared.campaigns.list.filter((it) => !it.demo).length > 0
  );

  useFetch({
    key: `tasks-filter-campaigns-${workspaceId}`,
    selector: (state) => filterCampaignsSelector(state, { workspaceId }).fetch,
    fetch: (fetchType) =>
      dispatch(
        fetchCampaignsForFilter({
          workspaceId,
          fetchType
        })
      )
  });

  useFetch({
    key: `tasks-dashboard-count-${workspaceId}`,
    selector: (state) =>
      workspaceStateSelector(state, { workspaceId }).dashboard.countFetch,
    fetch: (fetchType) =>
      dispatch(fetchDashboardTasksCount({ workspaceId, fetchType }))
  });

  const loading = useFetch({
    key: `tasks-dashboard-${workspaceId}`,
    selector: (state) =>
      workspaceStateSelector(state, { workspaceId }).dashboard.fetch,
    fetch: (fetchType) =>
      dispatch(fetchDashboardTaskList({ workspaceId, fetchType }))
  });

  useEffect(() => {
    dispatch(setDashboardListNeedRefresh({ workspaceId }));
  }, [workspaceId, dispatch]);

  const onLoadMore = useCallback(() => {
    dispatch(loadMoreDashboardTasks({ workspaceId }));
  }, [workspaceId, dispatch]);

  const onFilterChange = useCallback(
    (listType: TasksInDashboardListType) => {
      dispatch(setDashboardTaskListParams({ listType, workspaceId }));
    },
    [workspaceId, dispatch]
  );

  const showEmpty = initiallyLoaded && !taskIds.length;

  return (
    <div className="card_container">
      <div className="card_container__header">
        <span className="copy">
          <LottieComponent className="icon" isLooped={false} view="star" />
          MY TASKS
          <div className="count">{count}</div>
        </span>
        <DashboardTasksFilter
          listType={listType}
          onFilterChange={onFilterChange}
        />
      </div>
      {!showEmpty && (
        <OverlayScrollbarsComponent>
          <div className="card_container__body">
            <div className="dashboard-tasks-container">
              <InfiniteScroll
                loading={loading}
                hasMore={hasMore}
                loader={<TaskListSkeleton count={count - taskIds.length} />}
                alwaysShowLoader
                next={onLoadMore}
              >
                {taskIds.map((taskId) => (
                  <TaskItem key={taskId} taskId={taskId} />
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </OverlayScrollbarsComponent>
      )}
      {showEmpty && (
        <div className="card_container__body">
          <div className="empty_task_list">
            <div style={{ textAlign: 'center' }}>
              <span>{isExistCampaign ? <EmptySvg /> : <StartTask />}</span>
              <span style={{ width: 224, margin: '20px auto 16px' }}>
                {isExistCampaign
                  ? 'Nothing to do? We don’t believe you.'
                  : 'Start a project to create your first task'}
              </span>
              {isExistCampaign ? (
                <Button
                  type="primary"
                  onClick={() =>
                    dispatch(
                      openNewTaskModal({
                        organizationId: nonNull(
                          currentWorkspace.organizationId
                        ),
                        autoAssignMe: true,
                        workspaceId: nonNull(currentWorkspace.id)
                      })
                    )
                  }
                >
                  Create task
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={() =>
                    dispatch(
                      toggleCampaignFolderCreationModal({
                        visible: true,
                        entity: 'campaign'
                      })
                    )
                  }
                >
                  Start project
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
