import React, { useCallback } from 'react';
import { Modal, Button } from 'antd';

import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { useTypedSelector } from '@hooks';
import { useDispatch } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import { toggleEntityDeletionModal } from '@redux/actions/modalAction';
import {
  deleteAssetAction,
  deleteFolderAction
} from '@redux/actions/assetsAction';
import {
  updateAssetsWorkspaceCounters,
  updateCampaignsWorkspaceCounters
} from '@redux/actions/workspaceCountersAction';
import {
  removeCampaign,
  updateCampaignCounters
} from '@redux/actions/campaignAction';
import { AssetItemDto, CampaignItemDto } from '@api/Api';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import { useAuth } from '@hooks/useAuth';
import { htmlSanitize } from '@helpers/htmlSanitize';

function DeleteEntityModal() {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { user } = useAuth(true, 'internal');
  const entityDeletionModal = useTypedSelector(
    ({ modal }) => modal.entityDeletionModal
  );

  const onFinish = async () => {
    if (entityDeletionModal?.page === 'campaign') {
      const campaign = entityDeletionModal.entity as CampaignItemDto;
      await removeCampaign({
        campaignId: entityDeletionModal.entity.id,
        permanent: entityDeletionModal?.permanent || false
      })(dispatch);

      dispatch(
        updateCampaignsWorkspaceCounters(
          entityDeletionModal?.permanent ? 'deletePermanent' : 'delete',
          entityDeletionModal.entity as CampaignItemDto
        )
      );
      await MixpanelService.track(
        mixpanel,
        user.id,
        MixpanelEventType.MANAGE_CAMPAIGNS,
        {
          campaignName: campaign.name,
          deleteCampaign: true,
          newCampaign: false,
          privateCampaign: campaign.isPrivate,
          sharedCampaign: !campaign.isPrivate,
          addTaskGroup: false,
          addContributor: false
        }
      );
    }
    if (entityDeletionModal?.page === 'folder') {
      await deleteFolderAction({
        folderId: entityDeletionModal.entity.id,
        permanent: entityDeletionModal?.permanent || false
      })(dispatch);
      dispatch(updateCampaignCounters('deleteFolder'));
    }
    if (entityDeletionModal?.page === 'asset') {
      const asset = entityDeletionModal.entity as AssetItemDto;
      await deleteAssetAction(
        {
          assetId: asset.id || '',
          permanent: entityDeletionModal?.permanent || false
        },
        asset.path?.[0]?.id
      )(dispatch);
      dispatch(updateCampaignCounters('deleteFile'));
      dispatch(
        updateAssetsWorkspaceCounters(
          entityDeletionModal?.permanent ? 'deletePermanent' : 'delete',
          entityDeletionModal?.entity as AssetItemDto
        )
      );

      await MixpanelService.track(
        mixpanel,
        user.id,
        MixpanelEventType.MANAGE_ASSETS,
        {
          addToFavorite: false,
          deleteAsset: true,
          newAsset: false,
          externalLinkShare: false,
          approvalRouting: false,
          newCommentAsset: false,
          annotationLeft: false,
          taskFromComment: false
        }
      );
    }

    dispatch(toggleEntityDeletionModal(null));
    if (entityDeletionModal?.func) entityDeletionModal.func();
  };
  const copies = useCallback(() => {
    if (entityDeletionModal?.page === 'folder') {
      return {
        title: 'Delete folder',
        subtitle: 'Do you want to delete this folder and all media inside?'
      };
    }

    if (entityDeletionModal?.page === 'asset') {
      const entity = entityDeletionModal?.entity as AssetItemDto;
      if (entity?.asset?.versions && Array.isArray(entity.asset.versions)) {
        const assetWithApprovalWorkflow = entity.asset.versions.find(
          (item) => item?.approvalWorkflow && !item.approvalWorkflow.isFinished
        );
        if (assetWithApprovalWorkflow) {
          return {
            title: 'Delete media?',
            subtitle: `<strong>${assetWithApprovalWorkflow.name}.${assetWithApprovalWorkflow.extension}</strong> has an approval routing in progress. Do you want to delete the media?`,
            btn: 'Yes, delete media'
          };
        }
      }
      return {
        title: 'Delete media',
        subtitle: `Are you sure you want to delete media and all (${entity.asset?.versionsCount}) versions?`
      };
    }

    if (entityDeletionModal?.page === 'campaign') {
      return {
        title: 'Delete project',
        subtitle: `Are you sure you want to delete this project and all media and tasks inside?`
      };
    }
  }, [entityDeletionModal])();
  return (
    <Modal
      visible={entityDeletionModal?.visible}
      footer={null}
      centered={true}
      closeIcon={<CrossSvg />}
      width={500}
      destroyOnClose
      zIndex={1010}
      onCancel={() => dispatch(toggleEntityDeletionModal(null))}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{ fontSize: 22, marginBottom: '0.5em' }}
        >
          {copies?.title} {entityDeletionModal?.permanent && 'forever'}
        </h2>

        <p
          className="modal_container__subtitle"
          style={{ marginBottom: '32px' }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: htmlSanitize(copies?.subtitle || '') as any
          }}
        />
        <div className="modal_container__actions">
          <Button
            type="text"
            size="large"
            style={{
              marginRight: '16px',
              border: 'none',
              height: 48,
              padding: '8px 32px'
            }}
            onClick={() => dispatch(toggleEntityDeletionModal(null))}
          >
            No
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={onFinish}
            style={{ minWidth: 124, height: 48, padding: '8px 15px' }}
          >
            {copies?.btn || 'Yes'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteEntityModal;
