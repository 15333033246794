import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { Upload } from 'antd';
import { useCurrentWorkspace } from '@hooks/workspace';
import AssetsEmptyState from '@components/Assets/AssetsEmptyState';
import { useTypedSelector } from '@hooks';
import {
  loadMoreAssets,
  moveAssetToFolder,
  setAssetList
} from '@redux/actions/assetsAction';
import { AssetsList, FoldersList } from '@components/Assets';
import {
  toggleCampaignFolderCreationModal,
  toggleMoveAssetToPrivateFolder
} from '@redux/actions/modalAction';
import { useLayout } from '@layouts/PageLayout';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { useAssetsUppy } from '@context/AssetsUppyContext';
import { useSearchParams } from 'react-router-dom';
import { OnboardingProcessContext } from '@context/OnboardingProcessProvider';
import { AssetsActionTypes } from '@redux/types/assetsType';

interface IAssetsTabProps {
  orderBy: string;
  searchQuery: string | undefined;
  activeTabKey: string;
  onRefreshAssetList?: () => void;
}

const assetsOrderParams = [
  'createdAt:ASC',
  'createdAt:DESC',
  'modifiedAt:ASC',
  'modifiedAt:DESC',
  'deletedAt:ASC',
  'deletedAt:DESC',
  'openedAt:ASC',
  'openedAt:DESC',
  'order:DESC'
] as const;

export default memo(function AssetsTab({
  searchQuery,
  activeTabKey,
  onRefreshAssetList,
  ...otherProps
}: IAssetsTabProps) {
  const [searchParams] = useSearchParams();
  const { onStartTour } = useContext(OnboardingProcessContext);
  const { currentView } = useLayout();
  const dispatch = useDispatch();
  const [workspace] = useCurrentWorkspace();
  const campaign = useTypedSelector(
    ({ campaign }) => campaign.selectedCampaign
  );
  const assetData = useTypedSelector(({ assets }) => assets.files);
  const folderData = useTypedSelector(({ assets }) => assets.folders);
  const campaignInfoModal = useTypedSelector(
    ({ modal }) => modal.campaignInfoModal
  );
  const uploadAssetModal = useTypedSelector(
    ({ modal }) => modal.uploadAssetModal
  );
  const endOfFoldersRef = useRef<any>();
  const endOfFilesRef = useRef<any>();
  const [countToFetchFolders, setCountToFetchFolders] = useState<number>(0);
  const [countToFetchFiles, setCountToFetchFiles] = useState<number>(0);
  const [listLoading, setListLoading] = useState<boolean>(true);

  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  const orderBy: (typeof assetsOrderParams)[number][] | undefined = useMemo(
    () =>
      otherProps.orderBy &&
      assetsOrderParams.includes(otherProps.orderBy as any)
        ? [otherProps.orderBy as (typeof assetsOrderParams)[number]]
        : undefined,
    [otherProps.orderBy]
  );

  useEffect(() => {
    const cleanup = () => {
      dispatch({
        type: AssetsActionTypes.SET_ASSETS_LIST,
        payload: { edges: null, count: null }
      });
      dispatch({
        type: AssetsActionTypes.SET_FOLDERS_LIST,
        payload: { edges: null, count: null }
      });
    };
    cleanup();
    return cleanup;
  }, [campaign?.id, dispatch]);

  useEffect(() => {
    (async () => {
      if (!campaign?.id) return;
      if (activeTabKey !== 'assets') return;
      setListLoading(true);
      const defaultParam = {
        workspaceId: campaign.workspaceId,
        campaignId: campaign.id,
        orderBy
      };
      await setAssetList({
        ...defaultParam,
        isFolder: true
      })(dispatch);
      await setAssetList({
        ...defaultParam,
        isFolder: false
      })(dispatch);
      setListLoading(false);
    })();
  }, [
    dispatch,
    onStartTour,
    activeTabKey,
    campaign?.workspaceId,
    campaign?.id,
    workspace.permissions,
    orderBy
  ]);

  useEffect(() => {
    if (listLoading) return;
    if (activeTabKey !== 'assets') return;
    if (campaignInfoModal?.visible) return;
    if (uploadAssetModal?.visible) return;
    const startTour = searchParamsRef.current.get('startTour') ?? undefined;
    if (startTour === 'upload-asset') {
      setTimeout(() => {
        onStartTour({ type: startTour as any });
      }, 1000);
    }
  }, [
    campaignInfoModal?.visible,
    uploadAssetModal?.visible,
    dispatch,
    onStartTour,
    activeTabKey,
    listLoading
  ]);

  const loadMoreFolders = async () => {
    if (!campaign) return;
    const params = {
      searchQuery,
      workspaceId: campaign.workspaceId,
      campaignId: campaign.id,
      isFolder: true,
      after: folderData.endCursor,
      orderBy
    };
    const count = Math.min(
      (folderData.count || 0) - (folderData.edges?.length || 0),
      25
    );
    setCountToFetchFolders(count);
    await loadMoreAssets(params)(dispatch);
    setCountToFetchFolders(0);
  };

  const loadMoreFiles = async () => {
    if (!campaign) return;
    const params = {
      searchQuery,
      workspaceId: campaign.workspaceId,
      campaignId: campaign.id,
      isFolder: false,
      after: assetData.endCursor,
      orderBy
    };
    const count = Math.min(
      (assetData?.count || 0) - (assetData?.edges?.length || 0),
      25
    );
    setCountToFetchFiles(count);

    await loadMoreAssets(params)(dispatch);
    setCountToFetchFiles(0);
  };

  const uppy = useAssetsUppy();
  const [isUploadingAssetsProcessVisible, setIUploadingAssetsProcessVisible] =
    useState<boolean>(false);

  const onAddFile = async (data: any) => {
    if (!campaign?.id) return;

    uppy.addFile({
      name: data.file.name,
      type: data.file.type,
      data: data.file,
      meta: {
        relativePath: new Date().toISOString(),
        campaignId: campaign.id
        // folderId: folder?.id
      }
    });
    setIUploadingAssetsProcessVisible(true);
    await uppy.upload();
  };

  const allowUpload = !!campaign?.permissions.createAssets && !campaign?.demo;
  const showEmptyState = allowUpload || !campaign?.foldersCount;

  return (
    <InfiniteScroll
      dataLength={folderData.edges?.length || 0}
      next={() => null}
      hasMore={folderData.hasNext || false}
      loader={<></>}
      style={{
        paddingRight: 34,
        paddingLeft: 34,
        marginRight: -34,
        marginLeft: -34
      }}
      onScroll={async (e: any) => {
        const foldersRect = endOfFoldersRef.current?.getBoundingClientRect();

        const filesRect = endOfFilesRef.current?.getBoundingClientRect();

        const foldersElemTop = foldersRect?.top;
        const foldersElemBottom = foldersRect?.bottom;

        const filesElemTop = filesRect?.top;
        const filesElemBottom = filesRect?.bottom;

        const isEndOfFoldersVisible =
          foldersElemTop < e.target.clientHeight + 200 &&
          foldersElemBottom >= 0;

        const isEndOfFilesVisible =
          filesElemTop < e.target.clientHeight + 300 && filesElemBottom >= 0;

        if (!folderData.edges) return;
        if (!assetData.edges) return;

        if (
          isEndOfFoldersVisible &&
          folderData.hasNext &&
          !countToFetchFolders
        ) {
          await loadMoreFolders();
        }

        if (isEndOfFilesVisible && assetData.hasNext) loadMoreFiles();
      }}
      height="calc(100vh - 288px)"
    >
      <div className="campaign_items">
        <button
          type="button"
          className="campaign_items__title"
          disabled={!allowUpload}
          onClick={() => {
            if (!allowUpload) return;
            dispatch(
              toggleCampaignFolderCreationModal({
                entity: 'folder',
                visible: true
              })
            );
          }}
        >
          {campaign?.foldersCount ? 'Folders' : 'Add folder'}{' '}
          {allowUpload && <PlusSvg />}
        </button>

        <FoldersList
          countToFetch={countToFetchFolders}
          isCardViewMode={currentView === 'tile'}
          canReorganize={otherProps.orderBy === 'order:DESC'}
          canDropInside={true}
          listLoading={!folderData.count && listLoading}
          onAssetDrop={(assetId, folderId) => {
            const asset = assetData.edges
              ?.map((x) => x.node)
              .find((x) => x.id === assetId);
            const folder = folderData.edges
              ?.map((x) => x.node)
              .find((x) => x.id === folderId);
            if (!asset?.asset || !folder?.folder) return;
            const { permissions } = asset.asset;
            const canMove =
              (folder.isPrivate && permissions.moveToPrivateFolder) ||
              (!folder.isPrivate && permissions.moveToPublicFolder);
            if (!canMove) return;
            const version = asset.asset.versions[0];
            const onConfirm = () =>
              dispatch(moveAssetToFolder({ assetId, folderId }, true));
            if (folder.isPrivate) {
              dispatch(
                toggleMoveAssetToPrivateFolder({
                  visible: true,
                  folderName: folder.folder.name,
                  assetsName: [`${version.name}.${version.extension}`],
                  callback: onConfirm
                })
              );
            } else onConfirm();
          }}
        />
        <div ref={endOfFoldersRef} />
        {(campaign?.assetsCount || 0) > 0 ? (
          <>
            <Upload
              multiple
              disabled={!allowUpload}
              showUploadList={false}
              customRequest={(data) => {
                if (!allowUpload) return;
                onAddFile(data);
              }}
            >
              <button type="button" className="campaign_items__title">
                Media {allowUpload && <PlusSvg />}
              </button>
            </Upload>
            <AssetsList
              searchQuery={searchQuery || null}
              assetsData={assetData}
              displayAssetsCount={campaign?.assetsCount || 0}
              isTileView={currentView === 'tile'}
              page="campaign"
              countToFetch={countToFetchFiles}
              canReorganize={otherProps.orderBy === 'order:DESC'}
              canCombine={true}
              listLoading={!assetData.count && listLoading}
              canShare={campaign?.additionalPermissions.canShareExternalLinks}
              isShowCompany={false}
              onRefreshAssetList={onRefreshAssetList}
            />
            <div ref={endOfFilesRef} />
          </>
        ) : (
          showEmptyState && <AssetsEmptyState isSearchEmptyState={false} />
        )}
      </div>
    </InfiniteScroll>
  );
});
