import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import {
  Dropdown,
  Menu,
  Table,
  Checkbox,
  Tooltip,
  Skeleton,
  Button
} from 'antd';
import './Table.less';
import { ReactComponent as FavoriteSVG } from '@assets/icons/favorite.svg';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { ReactComponent as PrivateEye } from '@assets/icons/eye-private.svg';
import { ReactComponent as CommentSvg } from '@assets/icons/comment-icon.svg';
import { AssetItemDto, AVMetadataInfoDto, SimpleFolderItemDto } from '@api/Api';
import { AssetsActionTypes, EntitiesStateProps } from '@redux/types/assetsType';
import {
  moveAssetToFolder,
  restoreDeletedAssetAction,
  setFavoriteAssetAction
} from '@redux/actions/assetsAction';
import { updateAssetsWorkspaceCounters } from '@redux/actions/workspaceCountersAction';
import {
  toggleApprovalModal,
  toggleEntityDeletionModal,
  toggleManageAssetVersionsModal,
  toggleMoveAssetToPrivateFolder,
  toggleRenameEntityModal,
  toggleShareAssetModal
} from '@redux/actions/modalAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { assetGetSimpleFoldersList } from '@api/Asset';
import Loader from '@components/Loader';
import UserAvatar from '@components/UserAvatar';
import { assetPreviewThumbnails } from '@helpers/assetPreviewThumbnails';
import AssetVersionsList from '@components/Assets/AssetVersionsList';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import { useAuth } from '@hooks/useAuth';
import Status from '@components/Approval/Status';
import { download } from '@helpers/download';
import { toast } from 'react-toastify';
import { ActionToast } from '@components/Toasts';
import { useOrganization } from '@components/OrganizationBoundary';
import { useCombineAssets } from '@helpers/useCombineAssets';

function AssetsListSkeleton() {
  return (
    <div className="assets_card_container" style={{ height: 56 }}>
      <div className="assets_card_container__preview">
        <Skeleton.Input style={{ width: '100%', height: 56 }} />
      </div>
    </div>
  );
}

function AssetsTable({
  assetsData,
  selectedOne,
  countToLoad,
  page,
  countToFetch,
  canShare,
  listLoading
}: {
  assetsData: EntitiesStateProps;
  selectedOne: boolean;
  countToLoad?: string[];
  page: string;
  countToFetch: number;
  canShare?: boolean;
  listLoading?: boolean;
}) {
  const [isCardMenuVisible, setIsCardMenuVisible] = useState<string | boolean>(
    false
  );
  const { currentOrganization } = useOrganization();
  const combineAssets = useCombineAssets();
  const [foldersList, setFoldersList] = useState<SimpleFolderItemDto[]>([]);
  const [domElement, setDomElement] = useState<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { user } = useAuth(true, 'internal');
  useEffect(() => {
    if (!isCardMenuVisible) return;
    (async () => {
      const asset = assetsData.edges?.find(
        (el) => el.node.id === isCardMenuVisible
      );
      if (!asset) return;
      const {
        data: { list }
      } = await assetGetSimpleFoldersList({
        campaignId: asset.node.campaignId,
        workspaceId: asset.node.workspaceId
      });
      setFoldersList(list);
    })();
  }, [isCardMenuVisible, assetsData.edges]);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 480,
      render: (text: string, { node }: { node: AssetItemDto }) => {
        const preparePreview = assetPreviewThumbnails({
          url: node.asset?.versions[0].metadata.preview?.url || '',
          status: node.asset?.versions[0].metadata.preview?.status || '',
          type: node.asset?.versions[0].metadata.type || '',
          size: 40,
          format: 'img'
        });
        return (
          <div
            className="assets_user_container asset_card_wr"
            data-id={node.id}
          >
            <div id={`asset_${node.id}`}>
              <Checkbox
                value={node.id}
                className={selectedOne ? 'checkbox_selected' : ''}
              />
              <div
                style={{ cursor: 'pointer' }}
                className="asset"
                onClick={() => {
                  if (!node.deletedAt)
                    navigate(
                      `/media-viewer/${node.id}/${
                        node.asset?.versions?.[0]?.id || ''
                      }`,
                      {
                        state: {
                          returnTo: location.pathname + location.search
                        }
                      }
                    );
                }}
              >
                {preparePreview === 'pending' && (
                  <Loader
                    classNameWrapper="table-assets-loader-wrapper"
                    spinSize={40}
                    isSpinning={true}
                  />
                )}
                {preparePreview !== 'pending' &&
                  typeof preparePreview === 'string' && (
                    <img
                      draggable={false}
                      style={
                        node.asset?.versions[0].metadata.preview?.url
                          ? {}
                          : { width: 150, height: 100 }
                      }
                      src={preparePreview || ''}
                      alt=""
                    />
                  )}
              </div>

              <div className="assets_user_title">
                <span className="title">
                  <span
                    className="copy"
                    onClick={() => {
                      if (!node.deletedAt)
                        navigate(
                          `/media-viewer/${node.id}/${
                            node.asset?.versions?.[0]?.id || ''
                          }`,
                          {
                            state: {
                              returnTo: location.pathname + location.search
                            }
                          }
                        );
                    }}
                  >
                    {node.asset?.versions[0]?.name}.
                    {node.asset?.versions[0]?.extension}
                  </span>

                  <AssetVersionsList
                    versionsCount={node.asset?.versionsCount}
                    permissions={node.asset?.permissions}
                    versions={node.asset?.versions}
                    assetId={node.id}
                  />
                  {(node.asset?.versions[0].commentsCount || 0) > 0 &&
                    !node.deletedAt && (
                      <div className="info__comments">
                        <CommentSvg />
                        <span>{node.asset?.versions[0].commentsCount}</span>
                      </div>
                    )}
                </span>
                <div className="assets_user_title_text_container">
                  <div style={node.deletedAt ? { display: 'none' } : {}}>
                    {!!node.asset?.versions[0].approvalWorkflow && (
                      <Status
                        showOverdueTag
                        approvalWorkflow={
                          node.asset.versions[0].approvalWorkflow
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Tooltip
                placement="bottomRight"
                overlayClassName="toggle_favorite_overlay"
                title={
                  !node.isFavorite ? 'Add to favorite' : 'Remove from favorite'
                }
              >
                <div
                  className={classNames(
                    node.deletedAt && 'favorite_icon_deleted',
                    'favorite_icon'
                  )}
                  onClick={async () => {
                    if (node.deletedAt) return;
                    if (!node?.isFavorite) {
                      dispatch(
                        setFavoriteAssetAction(
                          {
                            assetId: node.id,
                            isFavorite: !node.isFavorite
                          },
                          page === 'favorite'
                        )
                      );
                      dispatch(
                        updateAssetsWorkspaceCounters(
                          node.isFavorite ? 'unsetFavorite' : 'setFavorite',
                          node
                        )
                      );
                      await MixpanelService.track(
                        mixpanel,
                        user.id,
                        MixpanelEventType.MANAGE_ASSETS,
                        {
                          addToFavorite: true,
                          deleteAsset: false,
                          newAsset: false,
                          externalLinkShare: false,
                          approvalRouting: false,
                          newCommentAsset: false,
                          annotationLeft: false,
                          taskFromComment: false
                        }
                      );
                      return;
                    }
                    dispatch(
                      setFavoriteAssetAction(
                        {
                          assetId: node.id,
                          isFavorite: !node.isFavorite
                        },
                        page === 'favorite'
                      )
                    );
                    dispatch(
                      updateAssetsWorkspaceCounters(
                        node.isFavorite ? 'unsetFavorite' : 'setFavorite',
                        node
                      )
                    );
                    toast(
                      <ActionToast
                        title="Media has been removed from favorites"
                        onUndo={undefined}
                        description={undefined}
                        closeToast={undefined}
                      />,
                      {
                        hideProgressBar: true,
                        bodyClassName: 'toast_container--invitation'
                      }
                    );
                  }}
                >
                  <FavoriteSVG
                    className={classNames(
                      node.isFavorite ? 'favorite' : 'non_favorite'
                    )}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      width: 190,
      render: (text: string, { node }: { node: AssetItemDto }) => {
        return (
          <div className="user_record">
            <Tooltip
              title={node.owner?.user?.name}
              placement="bottom"
              overlayClassName="toggle_favorite_overlay"
            >
              <div className="user_record__avatar">
                <UserAvatar
                  isActive={true}
                  size="large"
                  src={node.owner?.user?.picture.url || ''}
                  userEmail={node.owner?.user?.email || ''}
                  userName={node.owner?.user?.name || ''}
                />
              </div>
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      width: 190,
      render: (text: string, { node }: { node: AssetItemDto }) => (
        <span className="time-state">
          {moment(node.createdAt).format('MMM DD, YYYY')}
        </span>
      )
    },
    {
      title: 'Modified',
      key: 'modified',
      dataIndex: 'modified',
      width: 160,
      render: (text: string, { node }: { node: AssetItemDto }) => (
        <span className="time-state">
          {moment(node.modifiedAt).format('MMM DD, YYYY')}
        </span>
      )
    },
    {
      title: '',
      key: 'action',
      render: (text: string, { node }: { node: AssetItemDto }) => (
        <Dropdown
          visible={node.id === isCardMenuVisible}
          onVisibleChange={(v) => {
            if (v) setIsCardMenuVisible(node.id);
            else setIsCardMenuVisible(v);
          }}
          overlay={
            <Menu
              defaultOpenKeys={['1']}
              defaultSelectedKeys={['1']}
              style={{ padding: '19px 12px' }}
              mode="vertical"
            >
              {!node.deletedAt ? (
                <>
                  {(node?.asset?.permissions.createReviews || canShare) && (
                    <Menu.Item
                      key="share"
                      onClick={() => {
                        const assetVersion = node?.asset?.versions?.[0];
                        if (assetVersion) {
                          dispatch(
                            toggleShareAssetModal({
                              visible: true,
                              assets: [{ asset: node }],
                              action: 'create'
                            })
                          );
                        }
                        setIsCardMenuVisible(false);
                      }}
                    >
                      Share
                    </Menu.Item>
                  )}

                  <Menu.Item
                    key="manage-version-stack"
                    onClick={() => {
                      dispatch(
                        toggleManageAssetVersionsModal({
                          versions: node?.asset?.versions || [],
                          permissions: node?.asset?.permissions || null,
                          versionsCount: node?.asset?.versionsCount || 0,
                          visible: true,
                          assetId: node.id || ''
                        })
                      );
                      setIsCardMenuVisible(false);
                    }}
                  >
                    Manage version stack
                  </Menu.Item>
                  {((currentOrganization.entity?.features.approvalRouting &&
                    node.asset?.versions[0].permissions
                      .manageApprovalWorkflow) ||
                    (!currentOrganization.entity?.features.approvalRouting &&
                      node.asset?.versions[0].approvalWorkflow)) && (
                    <Menu.Item
                      key="manage-aw"
                      onClick={() => {
                        setIsCardMenuVisible(false);
                        if (!node.asset?.versions[0]) return;

                        dispatch(
                          toggleApprovalModal({
                            visible: true,
                            assetVersions: [node.asset?.versions[0]],
                            isLastVersion: true,
                            assetIds: [node.id],
                            campaignId: node.campaignId,
                            allowCreate:
                              !!node.asset?.permissions.createApprovalWorkflows,
                            callback: (asset) => {
                              dispatch({
                                type: AssetsActionTypes.UPDATE_ASSET,
                                payload: { asset }
                              });
                            }
                          })
                        );
                      }}
                    >
                      {node.asset?.versions[0].permissions
                        .manageApprovalWorkflow
                        ? node.asset?.versions[0].approvalWorkflow
                          ? 'Manage approval routing'
                          : 'Create approval routing'
                        : 'View approval routing'}
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key="add_to_favorites"
                    onClick={async () => {
                      if (node.deletedAt) return;
                      if (!node?.isFavorite) {
                        dispatch(
                          setFavoriteAssetAction(
                            {
                              assetId: node.id,
                              isFavorite: !node.isFavorite
                            },
                            page === 'favorite'
                          )
                        );
                        dispatch(
                          updateAssetsWorkspaceCounters(
                            node.isFavorite ? 'unsetFavorite' : 'setFavorite',
                            node
                          )
                        );
                        await MixpanelService.track(
                          mixpanel,
                          user.id,
                          MixpanelEventType.MANAGE_ASSETS,
                          {
                            addToFavorite: true,
                            deleteAsset: false,
                            newAsset: false,
                            externalLinkShare: false,
                            approvalRouting: false,
                            newCommentAsset: false,
                            annotationLeft: false,
                            taskFromComment: false
                          }
                        );
                        setIsCardMenuVisible(false);
                        return;
                      }
                      dispatch(
                        setFavoriteAssetAction(
                          {
                            assetId: node.id,
                            isFavorite: !node.isFavorite
                          },
                          page === 'favorite'
                        )
                      );
                      dispatch(
                        updateAssetsWorkspaceCounters(
                          node.isFavorite ? 'unsetFavorite' : 'setFavorite',
                          node
                        )
                      );
                      toast(
                        <ActionToast
                          title="Media has been removed from favorites"
                          onUndo={undefined}
                          description={undefined}
                          closeToast={undefined}
                        />,
                        {
                          hideProgressBar: true,
                          bodyClassName: 'toast_container--invitation'
                        }
                      );

                      setIsCardMenuVisible(false);
                    }}
                  >
                    {node.isFavorite
                      ? 'Remove from favorites'
                      : 'Add to favorites'}
                  </Menu.Item>
                  {node.asset?.versions[0].permissions.download && (
                    <>
                      {node.asset?.versions[0].metadata.type === 'video' ? (
                        <Menu.SubMenu
                          key="download"
                          title="Download"
                          popupClassName="folders_list"
                          disabled={!foldersList.length}
                        >
                          <Menu.Item
                            key="original"
                            onClick={() => {
                              download(
                                node.asset?.versions[0].sourceUrl,
                                node.asset?.versions[0].name
                              );
                            }}
                          >
                            Original{' '}
                            {
                              node.asset?.versions[0].metadata.info.avInfo
                                ?.video?.height
                            }
                            p
                          </Menu.Item>
                          <Menu.Divider />
                          {Object.entries(
                            node.asset?.versions[0].metadata.videoProxies
                          )
                            .reverse()
                            .filter((el) => el[1].status !== 'skipped')
                            .map((el) => {
                              const info = node.asset?.versions[0].metadata
                                .info as AVMetadataInfoDto;
                              if (`${info.avInfo?.video?.height}` === el[0])
                                return;
                              return (
                                <Menu.Item
                                  key={el[0]}
                                  disabled={el[1].status !== 'ready'}
                                  onClick={() => {
                                    download(el[1].url);
                                  }}
                                >
                                  {el[0]}p mp4
                                </Menu.Item>
                              );
                            })}
                        </Menu.SubMenu>
                      ) : (
                        <Menu.Item
                          key="download"
                          disabled={
                            !node.asset?.versions[0].permissions.download
                          }
                          onClick={() => {
                            if (!node.asset) return;
                            const { name, sourceUrl } = node.asset.versions[0];
                            download(sourceUrl, name);
                            setIsCardMenuVisible(false);
                          }}
                        >
                          Download
                        </Menu.Item>
                      )}
                    </>
                  )}
                  {node.asset?.permissions.rename && (
                    <Menu.Item
                      key="rename"
                      onClick={async () => {
                        dispatch(
                          toggleRenameEntityModal({
                            visible: true,
                            entity: node,
                            page: 'media'
                          })
                        );
                        setIsCardMenuVisible(false);
                        if (!node.isFavorite) {
                          await MixpanelService.track(
                            mixpanel,
                            user.id,
                            MixpanelEventType.MANAGE_ASSETS,
                            {
                              addToFavorite: true,
                              deleteAsset: false,
                              newAsset: false,
                              externalLinkShare: false,
                              approvalRouting: false,
                              newCommentAsset: false,
                              annotationLeft: false,
                              taskFromComment: false
                            }
                          );
                        }
                      }}
                    >
                      Rename
                    </Menu.Item>
                  )}
                  {(node.asset?.permissions.moveToPrivateFolder ||
                    node.asset?.permissions.moveToPublicFolder) && (
                    <Menu.SubMenu
                      key="move_to"
                      title="Move to"
                      popupClassName="folders_list"
                      disabled={!foldersList.length}
                    >
                      {!!node.asset?.permissions.moveToCampaign &&
                        node.path.length > 0 && (
                          <>
                            <p
                              style={{
                                fontSize: 13,
                                color: '#969696',
                                margin: '0 0 0 16px'
                              }}
                            >
                              PROJECT
                            </p>
                            <Menu.Item
                              key="campaign"
                              onClick={async () => {
                                dispatch(
                                  moveAssetToFolder(
                                    {
                                      assetId: node.id
                                    },
                                    page === 'campaign',
                                    node.path
                                  )
                                );
                                setIsCardMenuVisible(false);
                              }}
                            >
                              {node.campaignName}
                            </Menu.Item>
                          </>
                        )}
                      <p
                        style={{
                          fontSize: 13,
                          color: '#969696',
                          margin: '0 0 0 16px'
                        }}
                      >
                        FOLDER
                      </p>
                      {foldersList?.map((el) => {
                        if (node.path.find((it) => it.id === el.id))
                          return null;
                        return (
                          <Menu.Item
                            key={el.id}
                            disabled={
                              el.isPrivate
                                ? !node.asset?.permissions.moveToPrivateFolder
                                : !node.asset?.permissions.moveToPublicFolder
                            }
                            onClick={async () => {
                              if (el.isPrivate) {
                                dispatch(
                                  toggleMoveAssetToPrivateFolder({
                                    folderName: el.name,
                                    visible: true,
                                    assetsName: [
                                      node.asset?.versions[0].name || ''
                                    ],
                                    callback: () => {
                                      dispatch(
                                        moveAssetToFolder(
                                          {
                                            assetId: node.id,
                                            folderId: el.id
                                          },
                                          page === 'campaign',
                                          node.path
                                        )
                                      );
                                    }
                                  })
                                );
                                setIsCardMenuVisible(false);
                                return;
                              }
                              dispatch(
                                moveAssetToFolder(
                                  {
                                    assetId: node.id,
                                    folderId: el.id
                                  },
                                  page === 'campaign',
                                  node.path
                                )
                              );
                              setIsCardMenuVisible(false);
                            }}
                          >
                            {el.name.length > 10 ? (
                              <Tooltip
                                placement="topLeft"
                                overlayClassName="toggle_favorite_overlay toggle_favorite_overlay--small"
                                title={el.name}
                              >
                                <span>{el.name}</span>
                              </Tooltip>
                            ) : (
                              <span>{el.name}</span>
                            )}
                            {el.isPrivate && <PrivateEye />}
                          </Menu.Item>
                        );
                      })}
                    </Menu.SubMenu>
                  )}
                  {node.asset?.permissions.delete && (
                    <Menu.Item
                      key="delete"
                      disabled={!node.asset?.permissions.delete}
                      onClick={() => {
                        dispatch(
                          toggleEntityDeletionModal({
                            entity: node,
                            page: 'asset',
                            permanent: false,
                            visible: true
                          })
                        );
                        setIsCardMenuVisible(false);
                      }}
                    >
                      Delete
                    </Menu.Item>
                  )}
                </>
              ) : (
                <>
                  {node.asset?.permissions.delete && (
                    <Menu.Item
                      key="delete_permanent"
                      disabled={!node.asset?.permissions.delete}
                      onClick={() => {
                        dispatch(
                          toggleEntityDeletionModal({
                            entity: node,
                            page: 'asset',
                            permanent: true,
                            visible: true
                          })
                        );
                        setIsCardMenuVisible(false);
                      }}
                    >
                      Delete forever
                    </Menu.Item>
                  )}
                  {node.asset?.permissions.delete && (
                    <Menu.Item
                      key="restore"
                      disabled={!node.asset?.permissions.delete}
                      onClick={() => {
                        dispatch(
                          restoreDeletedAssetAction({
                            assetId: node.id
                          })
                        );
                        dispatch(
                          updateAssetsWorkspaceCounters('restore', node)
                        );
                        setIsCardMenuVisible(false);
                      }}
                    >
                      Restore
                    </Menu.Item>
                  )}
                </>
              )}
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button type="text" size="small" style={{ padding: 0 }}>
            <ActionSvg style={{ marginRight: 0 }} />
          </Button>
        </Dropdown>
      )
    }
  ];
  return (
    <div className="table_container">
      {!listLoading && (countToLoad?.length || 0) > 0 ? (
        <Table
          pagination={false}
          columns={columns}
          locale={{
            emptyText: countToLoad?.map((it, idx) => (
              <div key={idx} className="skeleton">
                <AssetsListSkeleton /> <br />
              </div>
            ))
          }}
          dataSource={assetsData.edges || []}
          rowKey={({ node }: { node: AssetItemDto }) => node.id}
          onRow={({ node }) => ({
            onMouseEnter: (event: any) => {
              const node =
                event.target.parentNode.parentNode.parentNode.parentNode;
              if (node.tagName !== 'TR') return;
              setTimeout(() => {
                node.childNodes.forEach((t: any) => {
                  t.classList.remove('ant-table-cell-row-hover');
                });
              }, 1);
            },
            draggable: true,
            onDragOver: (e) => {
              const type = e.dataTransfer.types
                .find((x) => x.startsWith('streamwork://type/'))
                ?.split('/')
                .slice(-1)[0];
              const id = e.dataTransfer.types
                .find((x) => x.startsWith('streamwork://id/'))
                ?.split('/')
                .slice(-1)[0];
              if (type === 'asset' && id !== node.id) {
                e.preventDefault();
              }
            },
            onDrop: (e) => {
              const type = e.dataTransfer.getData('streamwork://type');
              if (type !== 'asset') return;
              const id = e.dataTransfer.getData('streamwork://id');
              combineAssets(id, node.id);
              if (!domElement) return;
              domElement.style.transform = 'scale(0)';
              setTimeout(() => {
                domElement.remove();
              }, 300);
            },
            onDrag: (e) => {
              if (!domElement) return;
              if (e.pageY || e.pageX) {
                domElement.style.top = `${e.pageY + 10}px`;
                domElement.style.left = `${e.pageX + 10}px`;
              }
            },
            onDragStart: (e) => {
              e.dataTransfer.setData('streamwork://id', node.id);
              e.dataTransfer.setData(`streamwork://id/${node.id}`, node.id);
              e.dataTransfer.setData('streamwork://type', 'asset');
              e.dataTransfer.setData('streamwork://type/asset', 'asset');
              const ctx = document
                ?.querySelector(`#asset_${node?.id || ''}`)
                ?.getElementsByTagName('img')[0];

              if (!ctx) return;

              const clonedNode = ctx?.cloneNode(true) as any;

              const dragImageContainer = document.createElement('div');
              const dragImage = ctx?.cloneNode(true) as any;
              const dragTitle = document.createElement('div');
              dragTitle.innerHTML = `${node.asset?.versions[0].name}.${node.asset?.versions[0].extension}`;
              dragImageContainer.classList.add('dragged_asset_container');

              document.body.appendChild(dragImageContainer);

              dragImageContainer.appendChild(dragImage);
              dragImageContainer.appendChild(dragTitle);

              setDomElement(dragImageContainer);
              e.dataTransfer.setDragImage(clonedNode, -1000, -1000);

              setTimeout(() => {
                dragImageContainer.style.transform = 'scale(1)';
              }, 1);
            },
            onDragEnd: () => {
              if (!domElement) return;
              domElement.style.transform = 'scale(0)';
              setTimeout(() => {
                domElement.remove();
              }, 300);
            }
          })}
        />
      ) : (
        countToLoad?.map((el, idx) => (
          <div key={idx} className="skeleton">
            <Skeleton.Input
              style={{ width: '100%', height: 56, marginBottom: 27 }}
              key={idx}
            />{' '}
            <br />{' '}
          </div>
        ))
      )}
      {new Array(countToFetch).fill('').map((el, idx) => (
        <div key={idx} className="skeleton">
          <Skeleton.Input
            style={{ width: '100%', height: 56, marginBottom: 27 }}
          />{' '}
          <br />{' '}
        </div>
      ))}
    </div>
  );
}

AssetsTable.defaultProps = {
  countToLoad: [],
  listLoading: false,
  canShare: false
};

export default AssetsTable;
