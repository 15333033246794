import React, { useMemo } from 'react';
import { Modal, Button, Form, Empty, Collapse, Skeleton, Spin } from 'antd';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import {
  countFolderMembers,
  deleteFolderMembers,
  findMemberGroupsToInviteInFolder,
  findMembersToInviteInFolder,
  inviteFolderMembers,
  listFolderMembers
} from '@api/Asset';
import useForm from 'antd/lib/form/hooks/useForm';
import { ActionToast } from '@components/Toasts';
import { toast } from 'react-toastify';
import {
  toggleManageFolderAccessModal,
  toggleReachedEntityLimitModal
} from '@redux/actions/modalAction';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@hooks';
import { InfiniteScroll } from '@components/InfiniteScroll/InfiniteScroll';
import { ManageFolderAccessModalProps } from '@redux/types/modalType';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import InviteMembersSelect, {
  SelectedItem
} from '@components/InviteMembersSelect/InviteMembersSelect';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { apiClient } from '@api/client';
import { FolderMembersTable } from '@components/Tables';
import FolderMemberGroupsTable from '@components/Tables/FolderMemberGroupsTable';

function ManageFolderModal() {
  const modalState = useTypedSelector(
    ({ modal }) => modal.manageFolderAccessModal
  );
  if (!modalState) return null;
  return <ManageFolderModalInner {...modalState} key={modalState.key} />;
}

function MembersSkeleton({ count }: { count: number }) {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          style={{
            height: 72,
            padding: '8px 0',
            borderBottom: '1px solid #f0f0f0'
          }}
        >
          <Skeleton.Button size="large" block />
        </div>
      ))}
    </>
  );
}

function ManageFolderModalInner(props: ManageFolderAccessModalProps) {
  const [form] = useForm();
  const modalState = props;
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const memberGroupsQueryEnabled = !!(
    modalState.visible && modalState.folder.isPrivate
  );
  const memberGroupsQuery = useInfiniteQuery({
    queryKey: ['folder', 'member-groups', 'list', modalState.folder.id],
    keepPreviousData: true,
    enabled: memberGroupsQueryEnabled,
    queryFn: async ({ pageParam, signal }) => {
      const { data } =
        await apiClient.asset.assetControllerListFolderMemberGroups(
          {
            folderId: modalState.folder.id,
            after: pageParam
          },
          { signal }
        );
      return data;
    },
    getNextPageParam: (lastPage) =>
      lastPage.hasNext ? lastPage.endCursor : undefined
  });
  const memberGroupsCountQuery = useQuery({
    queryKey: ['folder', 'member-groups', 'count', modalState.folder.id],
    keepPreviousData: true,
    enabled: memberGroupsQueryEnabled,
    queryFn: async ({ signal }) => {
      const { data } =
        await apiClient.asset.assetControllerCountFolderMemberGroups(
          {
            folderId: modalState.folder.id
          },
          { signal }
        );
      return data.count;
    }
  });
  const membersQueryEnabled = !!modalState.visible;
  const membersQuery = useInfiniteQuery({
    queryKey: ['folder', 'members', 'list', modalState.folder.id],
    keepPreviousData: true,
    enabled: membersQueryEnabled,
    queryFn: async ({ pageParam }) => {
      const { data } = await listFolderMembers({
        folderId: modalState.folder.id,
        after: pageParam
      });
      return data;
    },
    getNextPageParam: (lastPage) =>
      lastPage.hasNext ? lastPage.endCursor : undefined
  });
  const membersCountQuery = useQuery({
    queryKey: ['folder', 'members', 'count', modalState.folder.id],
    keepPreviousData: true,
    enabled: membersQueryEnabled,
    queryFn: async () => {
      const { data } = await countFolderMembers({
        folderId: modalState.folder.id
      });
      return data.count;
    }
  });
  const memberGroupsList = useMemo(
    () =>
      memberGroupsQuery.data?.pages.flatMap((x) =>
        x.edges.map((x) => x.node)
      ) ?? [],
    [memberGroupsQuery.data]
  );
  const memberGroupsCount = memberGroupsCountQuery.data ?? 0;
  const membersList = useMemo(
    () =>
      membersQuery.data?.pages.flatMap((x) => x.edges.map((x) => x.node)) ?? [],
    [membersQuery.data]
  );
  const membersCount = membersCountQuery.data ?? 0;
  const loading =
    (memberGroupsQueryEnabled && memberGroupsQuery.isLoading) ||
    (membersQueryEnabled && membersQuery.isLoading);
  const empty = loading || (!memberGroupsList.length && !membersList.length);
  const showGroups = modalState.folder.isPrivate && !!memberGroupsList.length;
  const showMembers = !!membersList.length;

  const accessMutation = useMutation({
    mutationKey: ['folder-access'],
    mutationFn: async ({
      id,
      isGroup,
      hasAccessToPrivate
    }: {
      isGroup: boolean;
      id: string;
      hasAccessToPrivate: boolean;
    }) => {
      if (hasAccessToPrivate) {
        await inviteFolderMembers({
          memberIds: isGroup ? [] : [id],
          groupIds: isGroup ? [id] : [],
          folderId: modalState.folder.id
        });
      } else {
        await deleteFolderMembers({
          folderId: modalState.folder.id,
          memberId: isGroup ? [] : [id],
          groupId: isGroup ? [id] : []
        });
      }
    },
    onMutate: async ({ isGroup, id, hasAccessToPrivate }) => {
      const queryKey = [
        'folder',
        isGroup ? 'member-groups' : 'members',
        'list',
        modalState.folder.id
      ];
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueriesData(queryKey, (prev: typeof membersQuery.data) => {
        if (!prev) return prev;
        return {
          ...prev,
          pages: prev.pages.map((page) => {
            return {
              ...page,
              edges: page.edges.map((edge) => {
                if (edge.node.id === id)
                  return {
                    ...edge,
                    node: { ...edge.node, hasAccessToPrivate }
                  };
                return edge;
              })
            };
          })
        };
      });
    },
    onSettled: () => {
      setTimeout(() => {
        if (!queryClient.isMutating({ mutationKey: ['folder-access'] })) {
          queryClient.invalidateQueries(['folder', 'members']);
          queryClient.invalidateQueries(['folder', 'member-groups']);
        }
      });
    }
  });

  const inviteMutation = useMutation({
    mutationKey: ['folder-invite'],
    mutationFn: async ({ userList }: { userList: SelectedItem[] }) => {
      if (!userList.length) return;
      await inviteFolderMembers({
        folderId: modalState.folder.id,
        memberIds: userList
          .filter((x) => x.type === 'new' || x.type === 'member')
          .map((x) => (x.type === 'new' ? x.email : x.id)),
        groupIds: userList.filter((x) => x.type === 'group').map((x) => x.id)
      });
    },
    onSuccess: (_, { userList }) => {
      dispatch(toggleManageFolderAccessModal(null));
      form.resetFields();
      if (userList.length === 0) return;
      toast(
        <ActionToast
          title={`Contributor${userList.length > 1 ? 's' : ''} added!`}
          onUndo={undefined}
          description={undefined}
          closeToast={undefined}
        />,
        {
          hideProgressBar: true,
          style: { width: '345px', height: 80 },
          bodyClassName: 'toast_container--invitation'
        }
      );
    },
    onError: (e: any) => {
      if (e?.response?.data?.errorCode === 'subscription_users_limit_error')
        dispatch(
          toggleReachedEntityLimitModal({
            visible: true,
            entity: 'contributor'
          })
        );
    },
    onSettled: () => {
      setTimeout(() => {
        if (!queryClient.isMutating({ mutationKey: ['folder-invite'] })) {
          queryClient.invalidateQueries(['folder', 'members']);
          queryClient.invalidateQueries(['folder', 'member-groups']);
          queryClient.invalidateQueries(['campaign', 'members']);
          queryClient.invalidateQueries(['campaign', 'member-groups']);
        }
      });
    }
  });

  return (
    <Modal
      open={modalState?.visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={912}
      onCancel={() => dispatch(toggleManageFolderAccessModal(null))}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{ fontSize: 22, marginBottom: 8 }}
        >
          {!modalState?.folder?.isPrivate
            ? 'Manage users'
            : 'Private folder access'}
        </h2>
        <p className="modal_container__subtitle" style={{ marginBottom: 24 }}>
          {modalState?.folder?.isPrivate &&
            'Anyone invited to this folder can access the whole project as well as any contents in this private folder.'}
        </p>
        <Form
          layout="vertical"
          onFinish={inviteMutation.mutate}
          form={form}
          initialValues={{ userList: [] }}
        >
          <Form.Item
            label="Share folder with"
            name="userList"
            style={{ position: 'relative' }}
          >
            <InviteMembersSelect
              key={modalState.folder.id}
              queryEnabled={modalState.visible}
              membersQueryKey={[
                'folder',
                'members',
                'find-to-invite',
                modalState.folder.id
              ]}
              membersQueryFn={async ({ pageParam, searchQuery, signal }) => {
                const { data } = await findMembersToInviteInFolder(
                  {
                    targetId: modalState.folder.id,
                    after: pageParam,
                    searchQuery
                  },
                  { signal }
                );
                return data;
              }}
              groupsQueryKey={[
                'folder',
                'member-groups',
                'find-to-invite',
                modalState.folder.id
              ]}
              groupsQueryFn={async ({ pageParam, searchQuery, signal }) => {
                const { data } = await findMemberGroupsToInviteInFolder(
                  {
                    targetId: modalState.folder.id,
                    after: pageParam,
                    searchQuery
                  },
                  { signal }
                );
                return data;
              }}
            />
          </Form.Item>
          <OverlayScrollbarsComponent
            className="table_container folder-members"
            style={{
              height: 'calc(100vh - 450px)',
              paddingRight: 25,
              marginRight: -20
            }}
          >
            {empty && (
              <Spin spinning={loading}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Spin>
            )}
            {!empty && (
              <Collapse defaultActiveKey={['groups', 'members']} ghost>
                {showGroups && (
                  <Collapse.Panel
                    header={
                      <div>
                        Groups{' '}
                        <span className="users_count">{memberGroupsCount}</span>
                      </div>
                    }
                    key="groups"
                  >
                    <InfiniteScroll
                      loading={
                        memberGroupsQuery.isFetchingNextPage
                          ? 'more'
                          : memberGroupsQueryEnabled &&
                            memberGroupsQuery.isLoading
                          ? 'initial'
                          : false
                      }
                      next={memberGroupsQuery.fetchNextPage}
                      hasMore={!!memberGroupsQuery.hasNextPage}
                      loader={
                        <MembersSkeleton
                          count={Math.max(
                            4,
                            memberGroupsCount - memberGroupsList.length
                          )}
                        />
                      }
                    >
                      <FolderMemberGroupsTable
                        folder={modalState.folder}
                        list={memberGroupsList}
                        onRevokeAssess={(id) =>
                          accessMutation.mutateAsync({
                            isGroup: true,
                            id,
                            hasAccessToPrivate: false
                          })
                        }
                        onShareAccess={(id) =>
                          accessMutation.mutateAsync({
                            isGroup: true,
                            id,
                            hasAccessToPrivate: true
                          })
                        }
                      />
                    </InfiniteScroll>
                  </Collapse.Panel>
                )}
                {showMembers && (
                  <Collapse.Panel
                    header={
                      <div>
                        Users{' '}
                        <span className="users_count">{membersCount}</span>
                      </div>
                    }
                    key="members"
                  >
                    <InfiniteScroll
                      loading={
                        membersQuery.isFetchingNextPage
                          ? 'more'
                          : membersQueryEnabled && membersQuery.isLoading
                          ? 'initial'
                          : false
                      }
                      next={membersQuery.fetchNextPage}
                      hasMore={membersQuery.hasNextPage ?? false}
                      loader={
                        <MembersSkeleton
                          count={Math.max(4, membersCount - membersList.length)}
                        />
                      }
                    >
                      <FolderMembersTable
                        folder={modalState.folder}
                        membersList={membersList}
                        onRevokeAssess={(id) =>
                          accessMutation.mutateAsync({
                            isGroup: false,
                            id,
                            hasAccessToPrivate: false
                          })
                        }
                        onShareAccess={(id) =>
                          accessMutation.mutateAsync({
                            isGroup: false,
                            id,
                            hasAccessToPrivate: true
                          })
                        }
                      />
                    </InfiniteScroll>
                  </Collapse.Panel>
                )}
              </Collapse>
            )}
          </OverlayScrollbarsComponent>
          <div className="modal_container__actions" style={{ marginTop: 32 }}>
            <Button
              type="ghost"
              size="large"
              style={{
                marginRight: '20px',
                border: 'none',
                height: 48,
                padding: '8px 15px'
              }}
              onClick={() => dispatch(toggleManageFolderAccessModal(null))}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              className="submit_form"
              htmlType="submit"
              style={{ width: 152, height: 48, padding: '8px 15px' }}
              loading={inviteMutation.isLoading}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default ManageFolderModal;
