import React, { useState } from 'react';
import {
  Avatar,
  Button,
  ConfigProvider,
  Switch,
  Table,
  type TableColumnsType
} from 'antd';
import { CampaignMemberGroupDto } from '@api/Api';
import { hashedColor } from '@helpers/hashedColor';
import useTypedSelector from '@hooks/useTypedSelector';
import { useCurrentWorkspace } from '@hooks/workspace';
import { toggleWorkspaceMemberGroupModal } from '@redux/actions/modalAction';
import { useDispatch } from 'react-redux';

function CampaignMemberGroupsTable({
  list,
  onShareAccess,
  onRevokeAssess
}: {
  list: CampaignMemberGroupDto[];
  onShareAccess: (a: string) => void | Promise<void>;
  onRevokeAssess: (a: string, b: boolean) => void | Promise<void>;
}) {
  const [workspace] = useCurrentWorkspace();
  const dispatch = useDispatch();
  const campaignInfoModal = useTypedSelector(
    ({ modal }) => modal.campaignInfoModal
  );
  const [switchLoading, setSwitchLoading] = useState<string | false>(false);
  const columns: TableColumnsType<CampaignMemberGroupDto> = [
    {
      title: 'Group name',
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => {
        return (
          <Button
            type="text"
            disabled={!workspace.permissions.listMembers}
            onClick={() =>
              dispatch(
                toggleWorkspaceMemberGroupModal({
                  visible: true,
                  workspace,
                  group: item
                })
              )
            }
          >
            <div className="user_record">
              <div className="user_record__avatar">
                <Avatar
                  size={40}
                  shape="square"
                  style={{
                    background: hashedColor(item.id, 'memberGroupAvatar')
                  }}
                >
                  {item.name[0].toUpperCase()}
                </Avatar>
              </div>
              <div className="user_record__info">
                <div className="name">{item.name}</div>
                <div className="email main-body-text main-body-text--tiny main-body-text--dark-gray">
                  {item.membersCount}{' '}
                  {item.membersCount === 1 ? 'member' : 'members'}
                </div>
              </div>
            </div>
          </Button>
        );
      }
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      title: () =>
        campaignInfoModal?.campaign?.isPrivate && (
          <span style={{ float: 'right' }}>Access to project</span>
        ),
      key: 'access_to_campaign',
      dataIndex: 'access_to_campaign',
      render: (_, item) => {
        const isSwitcherDisabled = item.hasAccessToPrivate
          ? !item.permissions.revokeAccess
          : !item.permissions.shareAccess;
        return (
          <div style={{ float: 'right' }}>
            {campaignInfoModal?.campaign?.isPrivate && (
              <>
                <Switch
                  onChange={async () => {
                    setSwitchLoading(item.id);
                    if (!item.hasAccessToPrivate) await onShareAccess(item.id);
                    else await onRevokeAssess(item.id, false);
                    setSwitchLoading(false);
                  }}
                  disabled={isSwitcherDisabled}
                  loading={switchLoading === item.id}
                  checked={item.hasAccessToPrivate}
                />
                <span style={{ marginLeft: 12 }}>
                  {item.hasAccessToPrivate ? 'On' : 'Off'}
                </span>
              </>
            )}
          </div>
        );
      },
      width: 180
    }
  ];
  return (
    <ConfigProvider renderEmpty={() => null}>
      <Table
        className="ant-table--hide-placeholder"
        pagination={false}
        columns={columns}
        dataSource={list}
        rowKey={(node) => node.id}
      />
    </ConfigProvider>
  );
}

export default CampaignMemberGroupsTable;
