import React, { useState } from 'react';
import {
  Table,
  Switch,
  Button,
  Tooltip,
  ConfigProvider,
  Popover,
  Avatar
} from 'antd';
import { CampaignMemberDto } from '@api/Api';
import { OrganizationRoleCopy, WorkspaceRoleCopy } from '@helpers/userRoles';
import { useTypedSelector } from '@hooks';
import { ActionToast } from '@components/Toasts';
import { toast } from 'react-toastify';
import UserAvatar from '@components/UserAvatar';
import { useOrganization } from '@components/OrganizationBoundary';
import { hashedColor } from '@helpers/hashedColor';
import { toggleWorkspaceMemberGroupModal } from '@redux/actions/modalAction';
import classNames from 'classnames';
import { useCurrentWorkspace } from '@hooks/workspace';
import { useDispatch } from 'react-redux';

function CampaignMembersTable({
  userList,
  onChangeOrderBy,
  onShareAccess,
  onRevokeAssess
}: {
  userList: CampaignMemberDto[];
  onChangeOrderBy: (a: string) => void;
  onShareAccess: (a: string) => void | Promise<void>;
  onRevokeAssess: (a: string, b: boolean) => void | Promise<void>;
}) {
  const { currentOrganization } = useOrganization();
  const [currentWorkspace] = useCurrentWorkspace();
  const dispatch = useDispatch();
  const [switchLoading, setSwitchLoading] = useState<string | false>(false);
  const campaignInfoModal = useTypedSelector(
    ({ modal }) => modal.campaignInfoModal
  );
  const [visibleGroupsPopover, setVisibleGroupsPopover] = React.useState<
    string | null
  >(null);
  const columns = [
    {
      title: 'User name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, node: CampaignMemberDto) => {
        return (
          <div className="user_record">
            <div className="user_record__avatar">
              <UserAvatar
                isActive={!node.invitationEmail}
                size={40}
                src={node.user?.picture.url || ''}
                userEmail={node.user?.email || ''}
                userName={node.user?.name || ''}
              />
            </div>
            <div className="user_record__info">
              <div className="name">{node.user?.name}</div>
              <div className="email main-body-text main-body-text--tiny main-body-text--dark-gray">
                {node.user?.email || node.invitationEmail}
                {node.invitationEmail && (
                  <Button
                    className="email-resend"
                    type="link"
                    size="small"
                    disabled={
                      currentOrganization.seatsCount.count >=
                      (currentOrganization.entity?.limits?.orgMembers ??
                        Number.POSITIVE_INFINITY)
                    }
                    onClick={async () => {
                      await onShareAccess(node.id);
                      toast(
                        <ActionToast
                          title="Invite sent!"
                          closeToast={undefined}
                          onUndo={undefined}
                          description={undefined}
                        />,
                        {
                          hideProgressBar: true,
                          delay: 222,
                          style: { width: '345px' },
                          bodyClassName: 'toast_container--invitation'
                        }
                      );
                    }}
                  >
                    Resend
                  </Button>
                )}
              </div>
            </div>
          </div>
        );
      }
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      sorter: true,
      render: (text: string, node: CampaignMemberDto) => {
        const isAdmin = node.organizationRole === 'organization://admin';
        const role =
          node.workspaceRole && !isAdmin
            ? WorkspaceRoleCopy[node.workspaceRole]
            : OrganizationRoleCopy[node.organizationRole];
        return role;
      },
      width: 160
    },
    {
      title: 'Groups',
      key: 'groups',
      dataIndex: 'groups',
      width: 90,
      render: (_: any, node: CampaignMemberDto) => {
        const { groups } = node;
        return (
          <Popover
            content={
              <div>
                <div className="user_campaigns__title">
                  Groups ({groups.length})
                </div>
                {groups.map((group) => {
                  return (
                    <div key={group.id} className="user_campaigns__list">
                      <Button
                        block
                        className="user_campaigns__name"
                        type="link"
                        disabled={!currentWorkspace.permissions.listMembers}
                        onClick={() => {
                          setVisibleGroupsPopover(null);
                          dispatch(
                            toggleWorkspaceMemberGroupModal({
                              group,
                              workspace: currentWorkspace,
                              visible: true
                            })
                          );
                        }}
                      >
                        <Avatar
                          size="small"
                          shape="square"
                          style={{
                            background: hashedColor(
                              group.id,
                              'memberGroupAvatar'
                            ),
                            marginRight: 16
                          }}
                        >
                          {group.name[0].toUpperCase()}
                        </Avatar>
                        {group.name}
                      </Button>
                    </div>
                  );
                })}
              </div>
            }
            className={classNames({
              user_campaigns: true,
              'user_campaigns--empty': !groups.length
            })}
            placement="bottomLeft"
            overlayClassName="user_campaigns__popover"
            trigger={groups.length ? ['hover'] : []}
            open={visibleGroupsPopover === node.id}
            onOpenChange={(open) => {
              if (open) {
                setVisibleGroupsPopover(node.id);
              } else {
                setVisibleGroupsPopover(null);
              }
            }}
          >
            <span>{groups.length}</span>
          </Popover>
        );
      }
    },
    {
      title: () =>
        campaignInfoModal?.campaign?.isPrivate && (
          <span style={{ float: 'right' }}>Access to project</span>
        ),
      key: 'access_to_campaign',
      dataIndex: 'access_to_campaign',
      render: (text: string, node: CampaignMemberDto) => {
        if (
          node?.accessControl === 'add_delete' &&
          node?.permissions.revokeAccess
        ) {
          return (
            <Button
              type="link"
              onClick={async () => {
                setSwitchLoading(node.id);
                await onRevokeAssess(node.id, true);
                setSwitchLoading(false);
              }}
              loading={switchLoading === node.id}
              disabled={!node.permissions.revokeAccess}
              className="main-body-text main-body-text--semibold main-body-text--dark-gray"
              style={{ float: 'right', padding: 0, height: 40 }}
            >
              Remove
            </Button>
          );
        }
        const isSwitcherDisabled = node.hasAccessToPrivate
          ? !node.permissions.revokeAccess
          : !node.permissions.shareAccess;
        let disabledTooltip: string | null = null;
        if (isSwitcherDisabled) {
          if (node.groupAccess.length) {
            const groupNames = node.groupAccess
              .map((group) => group.name)
              .sort((a, b) => a.localeCompare(b))
              .join(', ');
            const multipleGroups = node.groupAccess.length > 1;
            disabledTooltip = `You can't change the access of this user because they are a member of the following group${
              multipleGroups ? 's' : ''
            }: ${groupNames}`;
          } else if (node.user?.me) {
            disabledTooltip = "You can't change your own access";
          } else if (node.accessControl === 'enable_disable') {
            disabledTooltip =
              "You can't change the access of this user because their role allows them to access all projects";
          }
        }
        return (
          <div style={{ float: 'right' }}>
            {campaignInfoModal?.campaign?.isPrivate && (
              <Tooltip
                title={disabledTooltip}
                overlayClassName="toggle_favorite_overlay"
              >
                <Switch
                  onChange={async () => {
                    setSwitchLoading(node.id);
                    if (!node.hasAccessToPrivate) await onShareAccess(node.id);
                    else await onRevokeAssess(node.id, false);
                    setSwitchLoading(false);
                  }}
                  disabled={isSwitcherDisabled}
                  loading={switchLoading === node.id}
                  checked={node.hasAccessToPrivate}
                />
                <span style={{ marginLeft: 12 }}>
                  {node.hasAccessToPrivate ? 'On' : 'Off'}
                </span>
              </Tooltip>
            )}
          </div>
        );
      },
      width: 180
    }
  ];
  return (
    <ConfigProvider renderEmpty={() => null}>
      <Table
        className="ant-table--hide-placeholder"
        pagination={false}
        columns={columns}
        dataSource={userList}
        rowKey={(node) => node.id}
        onChange={(pagination, filters, sorter: any) => {
          let sortOrder = 'default:ASC';
          if (sorter.order === 'descend') sortOrder = 'role:DESC';
          else if (sorter.order === 'ascend') sortOrder = 'role:ASC';

          onChangeOrderBy(sortOrder);
        }}
      />
    </ConfigProvider>
  );
}

export default CampaignMembersTable;
