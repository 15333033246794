import { AssetForApprovalDto, PageDto } from '@api/Api';
import ApprovalDropDownMenu from '@components/EntitiesDropdownMenu/ApprovalDropDownMenu';
import CreatedCell from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/ApprovalsTable/components/CreatedCell';
import DecisionsCell from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/ApprovalsTable/components/DecisionsCell';
import ManagersCell from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/ApprovalsTable/components/ManagersCell';
import NameCell from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/ApprovalsTable/components/NameCell';
import ProgressCell from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/ApprovalsTable/components/ProgressCell';
import { Table } from 'antd';
import React, { memo, useCallback, useMemo, useRef, useEffect } from 'react';
import { ReactComponent as ArrowSvg } from '@assets/icons/arrow-down.svg';
import './ApprovalsTable.less';
import ApprovalsCollapse from '@pages/Campaigns/CampaignItem/components/ApprovalsTab/components/ApprovalsCollapse/ApprovalsCollapse';
import { useSearchParams } from 'react-router-dom';
import { Key } from 'antd/lib/table/interface';
import classNames from 'classnames';

type ApprovalsTableProps = {
  setApprovalsCount: any;
  setApprovals: any;
  approvals: PageDto & {
    edges: { node: AssetForApprovalDto; cursor: string }[];
  };
};

export default memo(function ApprovalsTable({
  approvals,
  setApprovalsCount,
  setApprovals
}: ApprovalsTableProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;
  const setSearchParamsRef = useRef(setSearchParams);
  setSearchParamsRef.current = setSearchParams;

  const approvalId = useMemo(() => {
    if (!approvals.edges) return undefined;

    const id = searchParamsRef.current.get('approvalId') ?? undefined;
    const key = id ?? approvals.edges[0].node.id;

    return [key] as readonly Key[];
  }, [approvals.edges]);

  useEffect(() => {
    if (!approvals.edges) return;
    const id = searchParamsRef.current.get('approvalId');
    if (!id) return;

    setTimeout(() => {
      document
        .querySelector(`[data-row-key="${id}"]`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      searchParamsRef.current.delete('approvalId');
      setSearchParamsRef.current(searchParamsRef.current, { replace: true });
    }, 1000);
  }, [approvals.edges]);

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '300px',
        colSpan: 2,
        render: (_: any, { node }: { node: AssetForApprovalDto }) => (
          <NameCell node={node} />
        )
      },
      {
        title: 'Managers',
        dataIndex: 'managers',
        key: 'managers',
        width: '18%',
        render: (_: any, { node }: { node: AssetForApprovalDto }) => (
          <ManagersCell node={node} />
        )
      },
      {
        title: 'Progress',
        dataIndex: 'progress',
        key: 'progress',
        width: '22%',
        render: (_: any, { node }: { node: AssetForApprovalDto }) => (
          <ProgressCell node={node} />
        )
      },
      {
        title: 'Created',
        dataIndex: 'created',
        key: 'created',
        width: '12%',
        render: (_: any, { node }: { node: AssetForApprovalDto }) => (
          <CreatedCell node={node} />
        )
      },
      {
        title: 'Decisions',
        dataIndex: 'decisions',
        key: 'decisions',
        width: '11%',
        render: (_: any, { node }: { node: AssetForApprovalDto }) => (
          <DecisionsCell node={node} />
        )
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 42,
        render: (_: any, { node }: { node: AssetForApprovalDto }) => (
          <div className="action-cell">
            <ApprovalDropDownMenu
              approval={node}
              setApprovalsCount={setApprovalsCount}
              setApprovals={setApprovals}
            />
          </div>
        )
      }
    ],
    []
  );

  const expandedRowRender = useCallback(
    ({ node }: { node: AssetForApprovalDto }) => {
      const aw = node.asset.versions[0].approvalWorkflow;
      if (!aw) return null;
      return (
        <ApprovalsCollapse
          approval={aw}
          assetId={node.id}
          assetVersionId={node.asset.versions[0].id}
        />
      );
    },
    []
  );
  const expandIcon = useCallback(
    ({ expanded, onExpand, record }) => (
      <ArrowSvg
        onClick={(e: any) => onExpand(record, e)}
        style={
          expanded
            ? { transform: 'rotate(180deg)', position: 'relative', left: -10 }
            : { position: 'relative', left: -10 }
        }
      />
    ),
    []
  );

  const dataSource = useMemo(() => {
    const groupedData = approvals.edges.reduce(
      (
        acc: { [key: string]: { node: AssetForApprovalDto; cursor: string }[] },
        item
      ) => {
        const batchId: string | null =
          item.node.asset.versions?.[0]?.approvalWorkflow?.batchId || null;
        if (batchId) {
          if (!acc[batchId]) acc[batchId] = [];
          acc[batchId].push(item);
        } else {
          acc[item.node.id] = [item];
        }
        return acc;
      },
      {}
    );
    return Object.values(groupedData);
  }, [approvals]);

  return (
    <div className="approvals-table-wrapper">
      <Table
        className="approvals-table approvals-table--header"
        columns={columns}
        tableLayout="fixed"
        expandable={{
          expandRowByClick: true,
          defaultExpandedRowKeys: approvalId,
          expandedRowRender,
          expandIcon
        }}
      />
      {dataSource.map((item, index) => {
        const isGroup = item.length > 1;
        const updateColumns = columns.map((col) =>
          col.key === 'name'
            ? { ...col, width: isGroup ? '284px' : '300px' }
            : col
        );
        return (
          <Table
            key={index}
            className={classNames(['approvals-table', { 'is-group': isGroup }])}
            columns={updateColumns}
            tableLayout="fixed"
            rowClassName="approvals-table--row"
            showHeader={false}
            dataSource={item}
            rowKey={({ node }) => node.id}
            loading={!approvals}
            pagination={{
              pageSize: approvals.edges.length
            }}
            expandable={{
              expandRowByClick: true,
              defaultExpandedRowKeys: approvalId,
              expandedRowRender,
              expandIcon
            }}
          />
        );
      })}
    </div>
  );
});
