import React, { useEffect, useState } from 'react';
import { Badge, Drawer, Spin } from 'antd';

import { ReactComponent as CreateSvg } from '@assets/icons/timeline/create.svg';
import { ReactComponent as PublicSvg } from '@assets/icons/timeline/public.svg';
import { ReactComponent as PrivateSvg } from '@assets/icons/timeline/private.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/timeline/plus.svg';
import { ReactComponent as CheckSvg } from '@assets/icons/timeline/check.svg';
import { ReactComponent as UploadSvg } from '@assets/icons/timeline/upload.svg';
import { ReactComponent as BranchSvg } from '@assets/icons/timeline/branch.svg';
import { ReactComponent as PencilSvg } from '@assets/icons/timeline/pencil.svg';
import { ReactComponent as EmptySvg } from '@assets/icons/timeline/no-timeline.svg';
import { ReactComponent as LightningSvg } from '@assets/icons/timeline/lightning.svg';
import { ReactComponent as CloseSvg } from '@assets/icons/cross.svg';

import { getCampaignTimeline } from '@api/Campaign';

import moment from 'moment';
import './CampaignTimeline.less';
import timeLineFromNow, {
  PAYLOAD_TYPES,
  pluralize
} from '@components/CampaignTimeline/utils';
import { CampaignEventDto, PageDto } from '@api/Api';
import InfiniteScroll from 'react-infinite-scroll-component';
import UserAvatar from '@components/UserAvatar';

type TItemTime = CampaignEventDto & {
  eventTime: string;
  ISOtime: string;
  count: number;
};

const actionCopy = (payload: any, arrLength: number | null) => {
  const payloadType = payload.type;
  const { taskName, assetName } = payload;

  switch (payloadType) {
    case PAYLOAD_TYPES.CAMPAIGN_CREATED:
      return {
        copy: `created the new project`,
        icon: <CreateSvg />
      };
    case PAYLOAD_TYPES.CAMPAIGN_VISIBILITY_CHANGED:
      return {
        copy: (
          <>
            updated the project access status to{' '}
            {payload.isPrivate ? <b>Private</b> : <b>Public</b>}
          </>
        ),
        icon: payload.isPrivate ? <PrivateSvg /> : <PublicSvg />
      };
    case PAYLOAD_TYPES.TASK_CREATED:
      return {
        copy: (
          <>
            created a new task <b>{taskName}</b>
          </>
        ),
        icon: <PlusSvg />
      };
    case PAYLOAD_TYPES.TASK_COMPLETED:
      return {
        copy: (
          <>
            marked the task <b>{taskName}</b> as completed
          </>
        ),
        icon: <CheckSvg />
      };
    case PAYLOAD_TYPES.ASSET_UPLOADED:
      return {
        copy: arrLength ? (
          <>
            uploaded <b>{arrLength}</b> new media
          </>
        ) : (
          <>
            uploaded a new media <b>{assetName}</b>
          </>
        ),
        icon: <UploadSvg />
      };
    case PAYLOAD_TYPES.ASSET_VERSION_UPLOADED: {
      return {
        copy: (
          <>
            uploaded new version for media <b>{assetName}</b>
          </>
        ),
        icon: <UploadSvg />
      };
    }
    case PAYLOAD_TYPES.ASSET_UPLOADED_MULTIPLE:
      return {
        copy: (
          <>
            uploaded {payload.asset_count} new
            {pluralize(payload.asset_count, 'asset')}
          </>
        ),
        icon: <UploadSvg />
      };
    case PAYLOAD_TYPES.APPROVAL_WORKFLOW_CREATED:
      return {
        copy: (
          <>
            created the approval routing for <b>{assetName}</b>
          </>
        ),
        icon: <BranchSvg />
      };
    case PAYLOAD_TYPES.APPROVAL_WORKFLOW_UPDATED:
      return {
        copy: (
          <>
            updated the approval routing for <b>{assetName}</b>
          </>
        ),
        icon: <PencilSvg />
      };
    case PAYLOAD_TYPES.ASSET_MADE_FINAL_DECISION:
      return {
        copy: (
          <>
            made a final decision for media <b>{assetName}</b>
          </>
        ),
        icon: <LightningSvg />
      };
    case PAYLOAD_TYPES.ASSET_UPLOADED_NEW_VERSION:
      return {
        copy: (
          <>
            uploaded new version for media <b>{assetName}</b>
          </>
        ),
        icon: <UploadSvg />
      };
    case PAYLOAD_TYPES.CAMPAIGN_DETAILS_UPDATED:
      return {
        copy: 'updated project details',
        icon: <PencilSvg />
      };
    default:
      return {
        copy: payloadType,
        icon: null
      };
  }
};

export default function CampaignTimeline({
  visible,
  setVisible,
  campaignId
}: {
  visible: boolean;
  setVisible: (v: boolean) => void;
  campaignId: string;
}) {
  const [campaignTimelines, setCampaignTimelines] = useState<
    (PageDto & { edges: { node: CampaignEventDto; cursor: string }[] }) | null
  >(null);
  const [lines, setLines] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const arraySplitter = (array: any) => {
    const skipIds: string[] = [];
    const points: any = [];
    let newArray = [];
    let _array: any = [];

    array.forEach((it: any, idx: any) => {
      if (skipIds.indexOf(it.id) > -1) {
        return;
      }
      if (it.payload.type !== PAYLOAD_TYPES.ASSET_UPLOADED) {
        _array[idx] = it;
        return;
      }

      const items = array.filter((t: any) => {
        const timeDifference = moment(it.ISOtime).diff(
          moment(t.ISOtime),
          'seconds'
        );

        return (
          t.payload.type === PAYLOAD_TYPES.ASSET_UPLOADED &&
          timeDifference <= 60 &&
          timeDifference >= 0 &&
          it.user?.email === t.user?.email
        );
      });
      items.forEach((element: any) => {
        skipIds.push(element.id);
      });

      if (items.length > 1) _array[idx] = { ...items[0], count: items.length };
      else _array[idx] = it;
    });
    _array = _array.filter((el: any) => el);

    for (let i = 0; i < _array.length; i++) {
      if (points.indexOf(_array[i].eventTime) === -1) {
        const filtered = _array.filter(
          (el: any) => el.eventTime === _array[i].eventTime
        );
        newArray.push({
          id: `${i}_${_array[i].eventTime}`,
          title: _array[i].eventTime,
          ISOtime: _array[i].ISOtime,
          values: filtered.map((el: any) => ({
            ...el,
            eventTime: _array[i].ISOtime
          }))
        });
        points.push(_array[i].eventTime);
      }
    }
    newArray = newArray.sort(
      (a, b) => +moment(b.ISOtime).format('X') - +moment(a.ISOtime).format('X')
    );

    return newArray;
  };

  const dateToFromNowDaily = (myDate: string): string => {
    const fromNow = moment(myDate).fromNow();
    return moment(myDate).calendar(null, {
      lastWeek: '[Last] dddd',
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      sameElse() {
        return `[${fromNow}]`;
      }
    });
  };
  useEffect(() => {
    setLines([]);
    if (visible) {
      (async () => {
        setLoading(true);
        const { data } = await getCampaignTimeline({
          campaignId
        });
        setCampaignTimelines(data);

        const dm = data.edges.map((el) => ({
          ...el.node,
          eventTime: dateToFromNowDaily(el.node.eventTime),
          ISOtime: el.node.eventTime
        }));
        setLines(arraySplitter(dm));
        setLoading(false);
      })();
    }
  }, [campaignId, visible]);

  const loadMoreData = async () => {
    const { data } = await getCampaignTimeline({
      campaignId,
      after: campaignTimelines?.endCursor || ''
    });
    const newData = {
      ...campaignTimelines,
      ...data,
      edges: [...(campaignTimelines?.edges || []), ...data.edges]
    };
    setCampaignTimelines(newData);
    const dm = data.edges.map((el) => ({
      ...el.node,
      eventTime: dateToFromNowDaily(el.node.eventTime),
      ISOtime: el.node.eventTime
    }));
    const newDm = [...lines, ...arraySplitter(dm)];
    setLines(newDm);
  };
  return (
    <Drawer
      title="Campaign timeline"
      placement="right"
      closeIcon={<CloseSvg />}
      width={488}
      onClose={() => setVisible(false)}
      visible={visible}
      className="timeline"
    >
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div style={{ height: '100%' }}>
          {campaignTimelines?.edges.length ? (
            <InfiniteScroll
              dataLength={campaignTimelines?.edges.length || 0}
              next={loadMoreData}
              hasMore={campaignTimelines?.hasNext || false}
              style={{
                overflowX: 'hidden',
                paddingRight: 32,
                marginRight: -32
              }}
              height="calc(100vh - 80px)"
              loader={<></>}
            >
              {lines.map((line: any) => {
                const { title, values, id } = line;
                return (
                  <div key={id} className="actions">
                    <div className="actions_period">{title}</div>
                    {values.map((el: TItemTime) => {
                      const { copy, icon } = actionCopy(el.payload, el.count);
                      return (
                        <div key={el.id} className="actions_item">
                          <div className="body">
                            <Badge offset={[-10, 30]} count={icon}>
                              <UserAvatar
                                size={40}
                                src={el.user?.picture.url || ''}
                                userName={el.user?.name ?? 'Deleted User'}
                                userEmail={el.user?.email || ''}
                                isActive
                              />
                            </Badge>
                            <div className="copy">
                              {el.user?.me
                                ? 'You'
                                : el.user?.name ?? 'Deleted User'}{' '}
                              {copy}
                            </div>
                          </div>
                          <div className="time">
                            {line.title === 'Today'
                              ? timeLineFromNow(el.ISOtime)
                              : moment(el.ISOtime).format('h:mm A')}{' '}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </InfiniteScroll>
          ) : (
            <div className="empty-timeline">
              <div className="empty-timeline-icon">
                <EmptySvg />
              </div>
              <div className="empty-timeline-title">
                <p>No recorded activity</p>
              </div>
              <div className="empty-timeline-description">
                <p>All activity for this project will be displayed here.</p>
              </div>
            </div>
          )}
        </div>
      )}
    </Drawer>
  );
}
