/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import { Checkbox, Skeleton } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { EntitiesStateProps } from '@redux/types/assetsType';
import { AssetsTable } from '@components/Tables';
import AssetsEmptyState from '@components/Assets/AssetsEmptyState';
import { AssetItemDto } from '@api/Api';
import AssetCard from '@components/Assets/AssetCard';
import { DraggableList } from '@components/DraggableList/DraggableList';
import { DraggableItem } from '@components/DraggableList';
import { reorganizeAssetList } from '@redux/actions/assetsAction';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { useCombineAssets } from '@helpers/useCombineAssets';
import BulkActions from './BulkActions';

function AssetsListSkeleton({ isTileView }: { isTileView: boolean }) {
  return (
    <div
      className="assets_card_container"
      style={!isTileView ? { height: 56 } : {}}
    >
      <div className="assets_card_container__preview">
        {isTileView ? (
          <>
            <div className="asset-image-container">
              <Skeleton.Input style={{ width: '100%', height: 152 }} />
            </div>

            <Skeleton.Input
              style={{ width: '100%', height: 40, marginTop: 11 }}
            />
          </>
        ) : (
          <Skeleton.Input style={{ width: '100%', height: 56 }} />
        )}
      </div>
    </div>
  );
}

AssetsList.defaultProps = {
  canReorganize: false,
  canShare: true,
  isShowCompany: true,
  listLoading: false
};

function AssetsList({
  searchQuery,
  assetsData,
  displayAssetsCount,
  isTileView,
  page,
  countToFetch,
  canReorganize,
  canCombine,
  canShare,
  isShowCompany,
  listLoading,
  onRefreshAssetList
}: {
  searchQuery: string | null;
  assetsData: EntitiesStateProps;
  displayAssetsCount: number;
  isTileView: boolean;
  page: string;
  countToFetch: number;
  canReorganize?: boolean;
  canCombine?: boolean;
  canShare?: boolean;
  isShowCompany?: boolean;
  listLoading?: boolean;
  onRefreshAssetList?: () => void;
}) {
  const dispatch = useTypedDispatch();

  const [selectedAssets, setSelectedAssets] = useState<
    { node: AssetItemDto; cursor: string }[]
  >([]);

  const onCheckboxChange = (v: CheckboxValueType[]) => {
    const assets = v
      .map((el) => {
        const asset = assetsData.edges?.find((asset) => asset.node.id === el);
        if (asset) return asset;
        return { node: null, cursor: '' };
      })
      .filter((item) => !!item.node);
    setSelectedAssets(assets);
  };

  const combineAssets = useCombineAssets();

  return (
    <>
      <Checkbox.Group
        value={selectedAssets.map((el) => el?.node?.id || '')}
        style={{
          display: 'block',
          paddingRight: 34,
          paddingLeft: 34,
          marginRight: -34,
          marginLeft: -34
        }}
        onChange={onCheckboxChange}
      >
        <div className="assets-container">
          {displayAssetsCount === 0 ? (
            <AssetsEmptyState isSearchEmptyState={page !== 'all'} />
          ) : (
            <>
              {page !== 'recent' &&
              searchQuery &&
              assetsData.edges?.length === 0 ? (
                <>
                  <AssetsEmptyState isSearchEmptyState={true} />
                </>
              ) : (
                <>
                  {isTileView ? (
                    <DraggableList
                      className="assets-container-list"
                      gap={16}
                      disableReorder={!canReorganize}
                      onReorder={(e) => {
                        const newList = [...(assetsData.edges ?? [])];
                        const movedItem = newList.splice(e.prevIndex, 1)[0];
                        newList.splice(e.newIndex, 0, movedItem);
                        const beforeAssetId = newList[e.newIndex - 1]?.node.id;
                        dispatch(
                          reorganizeAssetList(
                            { id: e.item.id, beforeAssetId },
                            newList
                          )
                        );
                      }}
                      onDropInside={(e) => {
                        combineAssets(e.item.id, e.insideItem.id);
                      }}
                    >
                      {!listLoading && assetsData?.edges
                        ? assetsData.edges
                            .concat(new Array(countToFetch).fill(''))
                            .map(({ node }, idx) => {
                              if (!node)
                                return (
                                  <AssetsListSkeleton isTileView={isTileView} />
                                );
                              return (
                                <DraggableItem
                                  key={node.id}
                                  id={node.id}
                                  type="asset"
                                  droppable={!!canCombine}
                                  data-tour={`asset-item-${idx}`}
                                  className="draggable-item-asset"
                                >
                                  <AssetCard
                                    asset={node}
                                    selectedOne={selectedAssets?.length > 0}
                                    page={page}
                                    canShare={canShare}
                                    isShowCompany={isShowCompany}
                                  />
                                </DraggableItem>
                              );
                            })
                        : new Array(displayAssetsCount)
                            .fill('')
                            .map((el, idx) => (
                              <AssetsListSkeleton isTileView={true} key={idx} />
                            ))}
                    </DraggableList>
                  ) : (
                    <AssetsTable
                      assetsData={assetsData}
                      countToFetch={countToFetch}
                      selectedOne={selectedAssets.length > 0}
                      countToLoad={new Array(displayAssetsCount).fill('') || []}
                      page={page}
                      canShare={canShare}
                      listLoading={listLoading}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Checkbox.Group>
      <BulkActions
        selectedAssets={selectedAssets}
        onRefreshAssetList={onRefreshAssetList}
        onClose={() => setSelectedAssets([])}
        page={page}
      />
    </>
  );
}

export default AssetsList;
