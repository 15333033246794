import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import { useTypedSelector } from '@hooks';
import { Modal, Button, Form, Input } from 'antd';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { removeWorkspace } from '@redux/actions/workspaceAction';
import { toggleWorkspaceOrganizationDeletionModal } from '@redux/actions/modalAction';
import { OrganizationItemDto, WorkspaceItemDto } from '@api/Api';
import { useAuth } from '@hooks/useAuth';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import { useOrganization } from '@components/OrganizationBoundary';
import { useDeleteOrganization } from '@hooks/organization';

function DeleteWorkspaceOrganizationModal() {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { user } = useAuth(true, 'internal');

  const deleteOrganization = useDeleteOrganization();
  const deletionModal = useTypedSelector(
    ({ modal }) => modal.workspaceOrganizationDeletionModal
  );
  const workspaceList = useTypedSelector(
    ({ workspace }) => workspace.workspaceList
  );

  const { currentOrganization } = useOrganization();
  const [form] = Form.useForm();
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    if (!deletionModal) return;
    if (deletionModal.entityName === 'workspace') {
      const workspace = workspaceList.find(
        (el) => el.id === deletionModal.entity?.id
      );
      if (!workspace) {
        dispatch(toggleWorkspaceOrganizationDeletionModal(null));
        return;
      }
      dispatch(
        toggleWorkspaceOrganizationDeletionModal({
          ...deletionModal,
          entity: workspace
        })
      );
    }
  }, [workspaceList]);

  useEffect(() => {
    if (!deletionModal?.visible) {
      form.setFields([
        {
          name: 'confirm',
          value: ''
        }
      ]);
      setShowDelete(false);
    }
  }, [form, deletionModal]);

  const onFinish = async ({ confirm }: { confirm: string }) => {
    if (!showDelete) {
      setShowDelete(true);
      return;
    }
    if (confirm === 'Delete') {
      if (deletionModal?.entityName === 'organization') {
        const org = deletionModal?.entity as OrganizationItemDto;
        deleteOrganization({ organizationId: org.id });
      }

      if (deletionModal?.entityName === 'workspace') {
        const ws = deletionModal?.entity as WorkspaceItemDto;
        await removeWorkspace({
          workspaceId: ws.id,
          organizationId: ws.organizationId
        })(dispatch);
        await MixpanelService.track(
          mixpanel,
          user.id,
          MixpanelEventType.MANAGE_WORKSPACES,
          {
            newWorkspaceName: ws.name,
            accessLink: !!ws.publicInvitationUrl,
            deleteWorkspace: true,
            updateWorkspace: false,
            oldWorkspaceName: null,
            uploadProfilePhoto: !!ws.picture.url,
            planType: currentOrganization.entity?.planType ?? 'free',
            addMember: false
          }
        );
      }

      dispatch(toggleWorkspaceOrganizationDeletionModal(null));
    } else {
      form.setFields([
        {
          name: 'confirm',
          errors: ['Please type “Delete” to confirm']
        }
      ]);
    }
  };

  const copies = useCallback(() => {
    if (!deletionModal?.visible) return null;
    if (deletionModal.entityName === 'workspace') {
      const ws = deletionModal?.entity as WorkspaceItemDto;
      return {
        title: (
          <>
            If you delete <b>{ws.name}</b>, you will delete all data and
            projects connected to the workspace. Are you sure that&apos;s what
            you&apos;d like to do?
          </>
        )
      };
    }
    if (deletionModal.entityName === 'organization') {
      const org = deletionModal?.entity as OrganizationItemDto;
      return {
        title: (
          <>
            If you delete <b>{org.name}</b>, you will delete all data and
            workspaces connected to the organization. Are you sure that&apos;s
            what you&apos;d like to do?
          </>
        )
      };
    }
  }, [deletionModal])();
  return (
    <Modal
      visible={deletionModal?.visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={500}
      onCancel={() => dispatch(toggleWorkspaceOrganizationDeletionModal(null))}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{ fontSize: 22, marginBottom: '0.5em' }}
        >
          {`Delete ${deletionModal?.entity?.name || ''}`}
        </h2>
        <p className="modal_container__subtitle">{copies?.title}</p>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          {showDelete && (
            <Form.Item label="Please type “Delete” to confirm" name="confirm">
              <Input size="large" autoComplete="off" />
            </Form.Item>
          )}
          <div className="modal_container__actions">
            <Button
              type="text"
              style={{
                marginRight: '16px',
                border: 'none',
                height: 48,
                padding: '8px 32px'
              }}
              onClick={() =>
                dispatch(toggleWorkspaceOrganizationDeletionModal(null))
              }
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: 216,
                height: 48,
                padding: '8px 15px'
              }}
            >
              {showDelete ? 'Delete' : `Delete ${deletionModal?.entityName}`}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default DeleteWorkspaceOrganizationModal;
