import { useOrganization } from '@components/OrganizationBoundary';
import { nonNull } from '@helpers/non-null';
import { useTypedSelector } from '@hooks';
import { useChangeOrganizationMemberRole } from '@hooks/organization';
import { organizationMemberStateSelector } from '@redux/selectors/organizations';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import React, { memo, useCallback, useMemo } from 'react';

type RoleCellProps = {
  memberId: string;
};

export default memo(function RoleCell({ memberId }: RoleCellProps) {
  const { currentOrganization } = useOrganization(true);
  const changeOrganizationMemberRole = useChangeOrganizationMemberRole();
  const organization = nonNull(currentOrganization.entity);
  const organizationId = organization.id;

  const member = useTypedSelector((state) =>
    organizationMemberStateSelector(state, { memberId, organizationId })
  );

  const options: DefaultOptionType[] = useMemo(() => {
    return [
      { label: 'Admin', value: 'organization://admin' },
      { label: 'Member', value: 'organization://workspace-member' }
    ];
  }, []);

  const onChangeOrganizationMemberRole = useCallback(
    (role) => {
      const members = [{ memberId, role }];
      changeOrganizationMemberRole({ organizationId, members });
    },
    [changeOrganizationMemberRole, memberId, organizationId]
  );

  if (!member) return null;

  return (
    <Select
      onChange={onChangeOrganizationMemberRole}
      dropdownClassName="user_role_select"
      options={options}
      value={member.organizationRole}
      disabled={!member.permissions.changeRole}
    />
  );
});
