import { useOrganization } from '@components/OrganizationBoundary';
import { bytesToSize } from '@helpers/bytesToSize';
import { toggleBillingModal } from '@redux/actions/modalAction';
import { Button } from 'antd';
import moment from 'moment';
import React, { memo, useMemo } from 'react';
import { useDispatch } from 'react-redux';

const planPrices: Record<string, { monthly: number; annually: number }> = {
  starter_20240711: {
    monthly: 20,
    annually: 216
  },
  team_20240711: {
    monthly: 40,
    annually: 432
  },
  free: {
    monthly: 0,
    annually: 0
  },
  starter: {
    monthly: 219,
    annually: 169 * 12
  },
  team: {
    monthly: 369,
    annually: 299 * 12
  }
};

export default memo(function PlanInformationSection() {
  const dispatch = useDispatch();
  const { currentOrganization } = useOrganization();

  const subscriptionType = currentOrganization.entity?.planType ?? 'free';
  const subscriptionPeriod =
    currentOrganization.entity?.planPeriod ?? 'monthly';
  const subscriptionIsCanceled =
    currentOrganization.entity?.planWillBeCanceled ?? false;
  const { currentPeriodEnd } = currentOrganization.subscription?.it as any;

  const subscriptionCostCopy = useMemo(() => {
    if (!planPrices[subscriptionType]) return '';
    const period = subscriptionPeriod === 'monthly' ? 'mo' : 'yr';
    const price = planPrices[subscriptionType][subscriptionPeriod];
    return `$${price}/${period}`;
  }, [subscriptionType, subscriptionPeriod]);

  const subscriptionNextPaymentCopy = useMemo(() => {
    if (!currentPeriodEnd || subscriptionIsCanceled) return '-';
    return (
      <div className="stat_value">
        {moment(currentPeriodEnd).format('MMM DD, YYYY')}
      </div>
    );
  }, [subscriptionIsCanceled, currentPeriodEnd]);
  return (
    <div className="billing_container__plan_information">
      <div className="header">
        <div className="copies">
          <div className="head-copy">Manage your plan</div>
          <div className="plan-copy">
            {currentOrganization.entity?.planName} Plan
          </div>
        </div>
        <div className="action">
          <Button
            type="primary"
            onClick={() => {
              dispatch(toggleBillingModal({ visible: true }));
            }}
          >
            Upgrade plan
          </Button>
        </div>
      </div>

      <div className="stats">
        <div className="stats_item">
          <div className="stat_title">Seats</div>
          <div className="stat_value">
            {currentOrganization.seatsCount.count} of{' '}
            {currentOrganization.entity?.limits.orgMembers ?? '∞'} seats
          </div>
        </div>
        <div className="stats_item">
          <div className="stat_title">Storage</div>
          <div className="stat_value">
            {bytesToSize({
              bytes: currentOrganization.assetsSummarySize.size,
              to: 0
            })}{' '}
            of {currentOrganization.entity?.limits.diskSpaceGB ?? '∞'} GB
          </div>
        </div>
        {!!subscriptionCostCopy && (
          <div className="stats_item">
            <div className="stat_title">Cost</div>
            <div className="stat_value">{subscriptionCostCopy}</div>
          </div>
        )}
        <div className="stats_item">
          <div className="stat_title">Next payment</div>
          <div className="stat_value">{subscriptionNextPaymentCopy}</div>
        </div>
      </div>
    </div>
  );
});
