import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, Form, Input, Avatar, Select } from 'antd';
import {
  findWorkspaceMembersToInviteInGroup,
  inviteWorkspaceMembers
} from '@api/Workspace';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import {
  toggleReachedEntityLimitModal,
  toggleWorkspaceMemberGroupInviteModal
} from '@redux/actions/modalAction';
import { WorkspaceMemberGroupInviteModalStateProps } from '@redux/types/modalType';
import InviteMembersSelect, {
  SelectedItem
} from '@components/InviteMembersSelect/InviteMembersSelect';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTypedSelector } from '@hooks';
import { hashedColor } from '@helpers/hashedColor';
import { WorkspaceRole } from '@helpers/userRoles';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import { useOrganization } from '@components/OrganizationBoundary';

type userRoleCopyProps = {
  [id: string]: string;
};

export const userRoleCopy: userRoleCopyProps = {
  'workspace://team-lead': 'Manager',
  'workspace://member': 'Member',
  'workspace://contributor': 'Contributor'
};

function WorkspaceMemberGroupInviteModal() {
  const modalState = useTypedSelector(
    ({ modal }) => modal.workspaceMemberGroupInviteModal
  );
  if (!modalState) return null;
  return (
    <WorkspaceMemberGroupInviteModalInner
      {...modalState}
      key={modalState.key}
    />
  );
}

function WorkspaceMemberGroupInviteModalInner(
  props: WorkspaceMemberGroupInviteModalStateProps
) {
  const dispatch = useDispatch();
  const { currentOrganization } = useOrganization();
  const [customMessageIsOpen, setCustomMessageIsOpen] =
    useState<boolean>(false);

  const canUpgradeInviteCustomMessage =
    !!currentOrganization?.entity?.features.inviteCustomMessage;

  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const inviteMutation = useMutation({
    mutationFn: async ({
      userList,
      role,
      message
    }: {
      userList: SelectedItem[];
      role: WorkspaceRole;
      message: string;
    }) => {
      if (!userList?.length) return;
      await inviteWorkspaceMembers(
        props.workspace.id,
        userList
          .filter((x) => x.type === 'new' || x.type === 'member')
          .map((x) => ({
            memberIdOrEmail: x.type === 'new' ? x.email : x.id,
            role,
            customMessage: message,
            groupId: props.group.id
          }))
      );
    },
    onError: (e: any) => {
      if (e?.response?.data?.errorCode === 'subscription_users_limit_error')
        dispatch(
          toggleReachedEntityLimitModal({
            visible: true,
            entity: 'user'
          })
        );
    },
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['workspace', 'members']),
        queryClient.invalidateQueries(['workspace', 'member-groups'])
      ]);
      dispatch(toggleWorkspaceMemberGroupInviteModal(null));
    }
  });

  const userList = Form.useWatch(['userList'], form) as
    | SelectedItem[]
    | undefined;
  const hasNewMembers = !!userList?.some((x) => x.type === 'new');

  return (
    <Modal
      open={props.visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={600}
      onCancel={() => {
        dispatch(toggleWorkspaceMemberGroupInviteModal(null));
      }}
      zIndex={1005}
    >
      <div className="modal_container">
        <h2 className="modal_container__title" style={{ fontSize: '22px' }}>
          Invite to group
        </h2>
        <div className="modal_container__entity_info">
          <Avatar
            size={40}
            shape="square"
            style={{
              background: hashedColor(props.group.id, 'memberGroupAvatar'),
              marginRight: 16
            }}
          >
            {props.group.name[0].toUpperCase()}
          </Avatar>
          <span>{props.group.name}</span>
        </div>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            userList: [],
            role: 'workspace://member',
            message: ''
          }}
          onFinish={inviteMutation.mutate}
        >
          <Form.Item name="userList" label="Invite user">
            <InviteMembersSelect
              key={props.group.id}
              queryEnabled={props.visible}
              membersQueryKey={[
                'workspace',
                'members',
                'find-to-invite',
                'group',
                props.workspace.id,
                props.group.id
              ]}
              membersQueryFn={async ({ pageParam, searchQuery, signal }) => {
                const { data } = await findWorkspaceMembersToInviteInGroup(
                  {
                    workspaceId: props.workspace.id,
                    groupId: props.group.id,
                    after: pageParam,
                    searchQuery
                  },
                  { signal }
                );
                return data;
              }}
            />
          </Form.Item>
          <Form.Item
            name="role"
            label="Workspace role for new members"
            style={{
              display: hasNewMembers ? 'block' : 'none'
            }}
          >
            <Select
              popupClassName="select_role_dropdown"
              size="large"
              listItemHeight={64}
              options={[
                {
                  value: 'workspace://team-lead',
                  disabled: !props.workspace.permissions.inviteTeamLead,
                  label: (
                    <>
                      <p className="title">Workspace lead</p>
                      <p className="description">Manages all projects</p>
                    </>
                  )
                },
                {
                  value: 'workspace://member',
                  disabled: !props.workspace.permissions.inviteMember,
                  label: (
                    <>
                      <p className="title">Member</p>
                      <p className="description">Can access projects</p>
                    </>
                  )
                },
                {
                  value: 'workspace://contributor',
                  disabled: !props.workspace.permissions.inviteContributor,
                  label: (
                    <>
                      <p className="title">Contributor</p>
                      <p className="description">
                        Can access specific projects
                      </p>
                    </>
                  )
                }
              ]}
            />
          </Form.Item>
          <UpgradePlanTooltip
            text=" to a Team plan to access this feature."
            isCanVisible={!canUpgradeInviteCustomMessage}
          >
            <Button
              className="modal_container__custom_message"
              type="link"
              onClick={() =>
                canUpgradeInviteCustomMessage &&
                setCustomMessageIsOpen((value) => !value)
              }
            >
              <PlusSvg /> Add custom message
              {!canUpgradeInviteCustomMessage && (
                <UpgradePlanSvg style={{ marginLeft: 8 }} />
              )}
            </Button>
          </UpgradePlanTooltip>
          {customMessageIsOpen && (
            <Form.Item name="message" style={{ position: 'relative' }} noStyle>
              <Input.TextArea
                rows={4}
                placeholder="Type your message here..."
                style={{ resize: 'none', padding: 12, marginTop: 8 }}
              />
            </Form.Item>
          )}
          <div className="modal_container__actions" style={{ marginTop: 32 }}>
            <Button
              type="text"
              size="large"
              style={{
                marginRight: 16,
                border: 'none',
                height: 48,
                padding: '8px 32px'
              }}
              onClick={() =>
                dispatch(toggleWorkspaceMemberGroupInviteModal(null))
              }
            >
              Cancel
            </Button>
            <Button
              loading={inviteMutation.isLoading}
              disabled={!userList?.length}
              type="primary"
              size="large"
              className="submit_form"
              htmlType="submit"
              style={{
                width: 216,
                height: 48,
                padding: '8px 15px'
              }}
            >
              Invite to group
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default WorkspaceMemberGroupInviteModal;
