import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Input,
  Button,
  Tooltip,
  Dropdown,
  Menu,
  Empty,
  Collapse,
  Skeleton,
  Spin
} from 'antd';
import { useMixpanel } from 'react-mixpanel-browser';
import { ReactComponent as SearchSvg } from '@assets/icons/search.svg';
import { ReactComponent as EyePrivateSvg } from '@assets/icons/eye-private.svg';
import { ReactComponent as EyePublicSvg } from '@assets/icons/eye-public.svg';
import { ActionToast } from '@components/Toasts';
import { setCampaignVisibilityAction } from '@redux/actions/campaignAction';
import { ReactComponent as ArrowDownSvg } from '@assets/icons/arrow-down.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { useTypedSelector } from '@hooks';
import {
  deleteMemberFromCampaign,
  getMembersCount,
  getMembersList,
  inviteMemberToCampaign
} from '@api/Campaign';
import { CampaignControllerListMembersParams } from '@api/Api';
import { CampaignMembersTable } from '@components/Tables';
import {
  toggleBillingModal,
  toggleCampaignInfoModal,
  toggleCampaignInvitationModal
} from '@redux/actions/modalAction';
import { useOrganization } from '@components/OrganizationBoundary';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import { useAuth } from '@hooks/useAuth';
import { nonNull } from '@helpers/non-null';
import classNames from 'classnames';
import { InfiniteScroll } from '@components/InfiniteScroll/InfiniteScroll';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useDebounced } from '@helpers/useDebounced';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { apiClient } from '@api/client';
import CampaignMemberGroupsTable from '@components/Tables/CampaignMemberGroupsTable';

const DropdownTitle: { [index: string]: any } = {
  shared: (
    <>
      <EyePublicSvg className="eye" style={{ marginRight: 4 }} />
      <span className="main-body-text main-body-text--semibold">
        Shared project
      </span>
    </>
  ),
  private: (
    <>
      <EyePrivateSvg className="eye" style={{ marginRight: 4 }} />
      <span className="main-body-text main-body-text--semibold">
        Private project
      </span>
    </>
  )
};

type CampaignMembersOrderBy = NonNullable<
  CampaignControllerListMembersParams['orderBy']
>[number];

function MembersSkeleton({ count }: { count: number }) {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          style={{
            height: 72,
            padding: '8px 0',
            borderBottom: '1px solid #f0f0f0'
          }}
        >
          <Skeleton.Button size="large" block />
        </div>
      ))}
    </>
  );
}

function CampaignMembersTab({
  selectedTab,
  changeSelectedTab
}: {
  selectedTab: string;
  changeSelectedTab: (tab: string) => void;
}) {
  const campaignInfoModal = useTypedSelector(
    ({ modal }) => modal.campaignInfoModal
  );
  const { currentOrganization } = useOrganization();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { user } = useAuth(true, 'internal');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounced(searchQuery, 250);
  const [orderBy, setOrderBy] = useState<CampaignMembersOrderBy>('default:ASC');
  const queryClient = useQueryClient();
  const memberGroupsQueryEnabled = !!(
    campaignInfoModal?.visible && campaignInfoModal?.campaign?.isPrivate
  );
  const memberGroupsQuery = useInfiniteQuery({
    queryKey: [
      'campaign',
      'member-groups',
      'list',
      campaignInfoModal?.campaign?.id,
      debouncedSearchQuery
    ],
    keepPreviousData: true,
    enabled: memberGroupsQueryEnabled,
    queryFn: async ({ pageParam, signal }) => {
      const { data } =
        await apiClient.campaign.campaignControllerListMemberGroups(
          {
            campaignId: nonNull(campaignInfoModal?.campaign?.id),
            after: pageParam,
            searchQuery: debouncedSearchQuery
          },
          { signal }
        );
      return data;
    },
    getNextPageParam: (lastPage) =>
      lastPage.hasNext ? lastPage.endCursor : undefined
  });
  const memberGroupsCountQuery = useQuery({
    queryKey: [
      'campaign',
      'member-groups',
      'count',
      campaignInfoModal?.campaign?.id,
      debouncedSearchQuery
    ],
    keepPreviousData: true,
    enabled: memberGroupsQueryEnabled,
    queryFn: async ({ signal }) => {
      const { data } =
        await apiClient.campaign.campaignControllerCountMemberGroups(
          {
            campaignId: nonNull(campaignInfoModal?.campaign?.id),
            searchQuery: debouncedSearchQuery
          },
          { signal }
        );
      return data.count;
    }
  });
  const membersQueryEnabled = !!campaignInfoModal?.visible;
  const membersQuery = useInfiniteQuery({
    queryKey: [
      'campaign',
      'members',
      'list',
      campaignInfoModal?.campaign?.id,
      debouncedSearchQuery,
      orderBy
    ],
    keepPreviousData: true,
    enabled: membersQueryEnabled,
    queryFn: async ({ pageParam, signal }) => {
      const { data } = await getMembersList(
        {
          campaignId: nonNull(campaignInfoModal?.campaign?.id),
          after: pageParam,
          searchQuery: debouncedSearchQuery,
          orderBy: [orderBy]
        },
        { signal }
      );
      return data;
    },
    getNextPageParam: (lastPage) =>
      lastPage.hasNext ? lastPage.endCursor : undefined
  });
  const membersCountQuery = useQuery({
    queryKey: [
      'campaign',
      'members',
      'count',
      campaignInfoModal?.campaign?.id,
      debouncedSearchQuery
    ],
    keepPreviousData: true,
    enabled: membersQueryEnabled,
    queryFn: async ({ signal }) => {
      const { data } = await getMembersCount(
        {
          campaignId: nonNull(campaignInfoModal?.campaign?.id),
          searchQuery: debouncedSearchQuery
        },
        { signal }
      );
      return data.count;
    }
  });
  const memberGroupsList = useMemo(
    () =>
      memberGroupsQuery.data?.pages.flatMap((x) =>
        x.edges.map((x) => x.node)
      ) ?? [],
    [memberGroupsQuery.data]
  );
  const memberGroupsCount = memberGroupsCountQuery.data ?? 0;
  const membersList = useMemo(
    () =>
      membersQuery.data?.pages.flatMap((x) => x.edges.map((x) => x.node)) ?? [],
    [membersQuery.data]
  );
  const membersCount = membersCountQuery.data ?? 0;
  const loading =
    (memberGroupsQueryEnabled && memberGroupsQuery.isLoading) ||
    (membersQueryEnabled && membersQuery.isLoading);
  const empty = loading || (!memberGroupsList.length && !membersList.length);
  const showGroups =
    campaignInfoModal?.campaign?.isPrivate && !!memberGroupsList.length;
  const showMembers = !!membersList.length;

  const onChangeOrderBy = async (orderBy: string) => {
    setOrderBy(orderBy as any);
  };

  const accessMutation = useMutation({
    mutationKey: ['campaign-access'],
    mutationFn: async ({
      isGroup,
      id,
      hasAccessToPrivate
    }: {
      isGroup: boolean;
      id: string;
      hasAccessToPrivate: boolean;
    }) => {
      const campaignId = nonNull(campaignInfoModal?.campaign?.id);
      if (hasAccessToPrivate) {
        await inviteMemberToCampaign({
          memberIdsOrEmails: isGroup ? [] : [id],
          groupIds: isGroup ? [id] : [],
          campaignId
        });
      } else {
        await deleteMemberFromCampaign({
          memberId: isGroup ? [] : [id],
          groupId: isGroup ? [id] : [],
          campaignId
        });
      }
    },
    onMutate: async ({ isGroup, id, hasAccessToPrivate }) => {
      const campaignId = nonNull(campaignInfoModal?.campaign?.id);
      const queryKey = [
        'campaign',
        isGroup ? 'member-groups' : 'members',
        'list',
        campaignId
      ];
      await queryClient.cancelQueries(queryKey);
      queryClient.setQueriesData(queryKey, (prev: typeof membersQuery.data) => {
        if (!prev) return prev;
        return {
          ...prev,
          pages: prev.pages.map((page) => {
            return {
              ...page,
              edges: page.edges.map((edge) => {
                if (edge.node.id === id)
                  return {
                    ...edge,
                    node: { ...edge.node, hasAccessToPrivate }
                  };
                return edge;
              })
            };
          })
        };
      });
    },
    onSettled: () => {
      setTimeout(() => {
        if (!queryClient.isMutating({ mutationKey: ['campaign-access'] })) {
          queryClient.invalidateQueries(['campaign', 'members']);
          queryClient.invalidateQueries(['campaign', 'member-groups']);
        }
      });
    }
  });

  const [accessCopy, setAccessCopy] = useState(
    campaignInfoModal?.campaign?.isPrivate ? 'private' : 'shared'
  );
  useEffect(() => {
    setAccessCopy(
      campaignInfoModal?.campaign?.isPrivate ? 'private' : 'shared'
    );
  }, [campaignInfoModal?.campaign?.isPrivate]);

  const upgradePrivateCampaigns =
    !currentOrganization?.entity?.features.privateCampaigns;

  const changeAccess = async (key: string) => {
    const type = accessCopy;
    if (type === key) return;
    const isPrivate = key === 'private';
    if (isPrivate && upgradePrivateCampaigns) return;
    setAccessCopy(key);
    await setCampaignVisibilityAction({
      campaignId: campaignInfoModal?.campaign?.id || '',
      isPrivate
    })(dispatch);
    if (!campaignInfoModal?.campaign) return;
    dispatch(
      toggleCampaignInfoModal({
        activeTab: 'members',
        visible: true,
        campaign: { ...campaignInfoModal.campaign, isPrivate }
      })
    );
    toast(
      <ActionToast
        title="Access updated"
        description={
          <>
            <span className="task">{campaignInfoModal?.campaign?.name}</span> is
            now {key}
          </>
        }
        onUndo={async () => {
          await setCampaignVisibilityAction({
            campaignId: campaignInfoModal?.campaign?.id || '',
            isPrivate: !isPrivate
          })(dispatch);
          if (!campaignInfoModal?.campaign) return;
          dispatch(
            toggleCampaignInfoModal({
              activeTab: 'members',
              visible: true,
              campaign: { ...campaignInfoModal.campaign, isPrivate: !isPrivate }
            })
          );
        }}
        closeToast={undefined}
      />
    );

    await MixpanelService.track(
      mixpanel,
      user.id,
      MixpanelEventType.MANAGE_CAMPAIGNS,
      {
        campaignName: nonNull(campaignInfoModal?.campaign?.name),
        deleteCampaign: false,
        newCampaign: false,
        privateCampaign: isPrivate,
        sharedCampaign: !isPrivate,
        addTaskGroup: false,
        addContributor: false
      }
    );
  };
  return (
    <>
      <p className="tab_description">
        Manage your project access here. Add anyone to the workspace to
        collaborate.
      </p>
      <div className="members_action">
        <div className="members_action__search">
          <Input
            prefix={<SearchSvg />}
            size="large"
            placeholder="Search by name or email"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
        </div>
        <div className="members_action__divider">
          <span>or</span>
        </div>
        <div className="members_action__wr">
          <Tooltip
            key={selectedTab}
            placement="bottomRight"
            overlayClassName="toggle_favorite_overlay"
            overlayStyle={{ maxWidth: 292 }}
            title={
              currentOrganization?.entity?.features.externalContributors ? (
                <span>
                  Invite anyone to this project to collaborate. Set their
                  permissions in{' '}
                  <button
                    type="button"
                    onClick={() => changeSelectedTab('settings')}
                  >
                    settings
                  </button>
                  .
                </span>
              ) : (
                <span>
                  You have reached the limit of contributors.{' '}
                  <span
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      if (!currentOrganization?.entity?.permissions.billing)
                        return;
                      dispatch(toggleBillingModal({ visible: true }));
                    }}
                  >
                    Upgrade
                  </span>{' '}
                  your plan to send more invitations
                </span>
              )
            }
          >
            <Button
              disabled={
                !currentOrganization?.entity?.features.externalContributors ||
                !campaignInfoModal?.campaign?.permissions.inviteContributors
              }
              type="primary"
              onClick={() => {
                if (!campaignInfoModal?.campaign) return;
                dispatch(
                  toggleCampaignInvitationModal({
                    visible: true,
                    campaign: campaignInfoModal?.campaign
                  })
                );
              }}
            >
              Invite contributor
            </Button>
          </Tooltip>
          {campaignInfoModal?.campaign?.permissions.setVisibility && (
            <Dropdown
              className="campaign_access"
              overlayClassName="campaign_access_overlay"
              trigger={['click']}
              overlay={
                <Menu
                  selectedKeys={[accessCopy]}
                  onClick={({ key }) => changeAccess(key)}
                  style={{ minWidth: 300 }}
                >
                  <Menu.Item key="shared">
                    <div>
                      <div className="item_title">
                        <EyePublicSvg />
                        Shared project
                      </div>
                      <div className="item_description">
                        Anyone in this workspace can access shared projects
                      </div>
                    </div>
                  </Menu.Item>
                  <Menu.Item key="private" disabled={upgradePrivateCampaigns}>
                    <Tooltip
                      title={
                        upgradePrivateCampaigns && (
                          <>
                            <span
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                if (
                                  !currentOrganization?.entity?.permissions
                                    .billing
                                )
                                  return;
                                dispatch(toggleBillingModal({ visible: true }));
                              }}
                            >
                              Upgrade
                            </span>{' '}
                            to a Team plan to set project as private.
                          </>
                        )
                      }
                      overlayClassName="toggle_favorite_overlay"
                      trigger={['hover']}
                    >
                      <div
                        className={classNames(
                          upgradePrivateCampaigns && 'disabled'
                        )}
                      >
                        <div className="item_title">
                          <EyePrivateSvg />
                          Private project
                          {upgradePrivateCampaigns && (
                            <UpgradePlanSvg className="upgrade-icon" />
                          )}
                        </div>
                        <div className="item_description">
                          Only invited members and contributors can access
                          private projects
                        </div>
                      </div>
                    </Tooltip>
                  </Menu.Item>
                </Menu>
              }
            >
              <span>
                {DropdownTitle[accessCopy]}
                <ArrowDownSvg className="arrow" />
              </span>
            </Dropdown>
          )}
        </div>
      </div>
      <OverlayScrollbarsComponent
        className="table_container campaign-members"
        style={{
          height: 'calc(100vh - 394px)',
          paddingRight: 25,
          marginRight: -20
        }}
      >
        {empty && (
          <Spin spinning={loading}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Spin>
        )}
        {!empty && (
          <Collapse defaultActiveKey={['groups', 'members']} ghost>
            {showGroups && (
              <Collapse.Panel
                header={
                  <div>
                    Groups{' '}
                    <span className="users_count">{memberGroupsCount}</span>
                  </div>
                }
                key="groups"
              >
                <InfiniteScroll
                  loading={
                    memberGroupsQuery.isFetchingNextPage
                      ? 'more'
                      : memberGroupsQueryEnabled && memberGroupsQuery.isLoading
                      ? 'initial'
                      : false
                  }
                  next={memberGroupsQuery.fetchNextPage}
                  hasMore={!!memberGroupsQuery.hasNextPage}
                  loader={
                    <MembersSkeleton
                      count={Math.max(
                        4,
                        memberGroupsCount - memberGroupsList.length
                      )}
                    />
                  }
                >
                  <CampaignMemberGroupsTable
                    list={memberGroupsList}
                    onRevokeAssess={(id) =>
                      accessMutation.mutateAsync({
                        isGroup: true,
                        id,
                        hasAccessToPrivate: false
                      })
                    }
                    onShareAccess={(id) =>
                      accessMutation.mutateAsync({
                        isGroup: true,
                        id,
                        hasAccessToPrivate: true
                      })
                    }
                  />
                </InfiniteScroll>
              </Collapse.Panel>
            )}
            {showMembers && (
              <Collapse.Panel
                header={
                  <div>
                    Users <span className="users_count">{membersCount}</span>
                  </div>
                }
                key="members"
              >
                <InfiniteScroll
                  loading={
                    membersQuery.isFetchingNextPage
                      ? 'more'
                      : membersQueryEnabled && membersQuery.isLoading
                      ? 'initial'
                      : false
                  }
                  next={membersQuery.fetchNextPage}
                  hasMore={!!membersQuery.hasNextPage}
                  loader={
                    <MembersSkeleton
                      count={Math.max(4, membersCount - membersList.length)}
                    />
                  }
                >
                  <CampaignMembersTable
                    userList={membersList}
                    onChangeOrderBy={onChangeOrderBy}
                    onRevokeAssess={(id) =>
                      accessMutation.mutateAsync({
                        isGroup: false,
                        id,
                        hasAccessToPrivate: false
                      })
                    }
                    onShareAccess={(id) =>
                      accessMutation.mutateAsync({
                        isGroup: false,
                        id,
                        hasAccessToPrivate: true
                      })
                    }
                  />
                </InfiniteScroll>
              </Collapse.Panel>
            )}
          </Collapse>
        )}
      </OverlayScrollbarsComponent>
    </>
  );
}

export default CampaignMembersTab;
